import {
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Flex,
  Spacer,
  Image,
} from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getThemeData } from '~/utils/theme';
import { Body2, Caption } from '../Typography';
import iconInfo from '~/assets/icon/icon_info.svg';
import iconNew from '~/assets/icon/icon_new.svg';

type FactorAccordionProps = {
  title: string;
  children: ReactElement;
  length: number;
  hasNew?: boolean;
};

function FactorAccordion({
  title,
  children,
  length,
  hasNew,
}: FactorAccordionProps) {
  //   const [isOpened, setIsOpened] = useState(false);
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { i18n } = useTranslation();

  return useMemo(
    () => (
      <Accordion allowMultiple>
        <AccordionItem border="none" padding="0px">
          <AccordionButton
            color="black"
            p="0px 24px"
            style={{ boxShadow: 'none', borderBottom: 'solid 1px #f7f7f7' }}
          >
            <AccordionIcon />
            <Flex
              paddingLeft="8px"
              paddingTop="16px"
              paddingBottom="16px"
              textAlign="left"
              flexDirection="row"
              flex={1}
            >
              <Body2 whiteSpace="nowrap">
                {/* @ts-ignore */}
                {t(`factorCategory.${title}.name`)}
              </Body2>
              <Tooltip
                color={themeData.colors.text2}
                box-shadow="0px 3px 3px #ccc"
                whiteSpace="pre-line"
                // @ts-ignore
                label={t(`factorCategory.${title}.description`)}
                background="white"
                padding="8px"
                border={`solid 1px ${themeData.colors.primary[500]}`}
                borderRadius="8px"
              >
                <Center tabIndex={0}>
                  <Image
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    _focus={{ boxShadow: 'none', borderWidth: '0px' }}
                    marginLeft="5px"
                    src={iconInfo}
                  />
                </Center>
              </Tooltip>
              {hasNew ? (
                <img
                  style={{ marginLeft: '8px' }}
                  alt="new-factor"
                  src={iconNew}
                />
              ) : null}
              <Spacer />
              <Tag text={length.toString()} />
            </Flex>
          </AccordionButton>
          <AccordionPanel p="8px 0px" bg="#fcfcfc">
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ),
    [i18n.language, children],
  );
}

FactorAccordion.defaultProps = {
  hasNew: false,
};

type TagProps = {
  text: string;
};
function Tag({ text }: TagProps) {
  const themeData = getThemeData();
  return (
    <Center
      borderRadius="16px"
      bg={themeData.colors.primary[100]}
      width="36px"
      height="24px"
      alignItems="center"
    >
      <Caption color={themeData.colors.primary[500]}>{text}</Caption>
    </Center>
  );
}

export default FactorAccordion;
