import React, { useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Typography,
  StackProps,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Button,
} from '@mui/material';
import { last, orderBy, pullAllBy, remove } from 'lodash';

import {
  ModelAsset,
  ModelAllocation,
  SimulationRecipe,
  AssetRecipe,
} from '~/api/portfolio';
import { CompanyPricesResult } from '~/api/strategy';

export type ModelAssetRecipePair = [
  asset: ModelAsset,
  price: number,
  size: number,
  count: number,
];

export type AssetAllocationSimulatorProps = {
  assets: ModelAllocation['assets'];
  assetSize: number;
  currency: string;
  priceHistory: { [c: number]: CompanyPricesResult };
  onUpdate?: (assets: SimulationRecipe) => void;
};

export const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'KRW',
    label: '₩',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'INR',
    label: '₹',
  },
  {
    value: 'HKD',
    label: 'HK$',
  },
  {
    value: 'JPY',
    label: '¥',
  },
  {
    value: 'CNY',
    label: '¥',
  },
  {
    value: 'BTC',
    label: '฿',
  },
];

export type Currency = typeof currencies[number]['value'];

export const findCurrencyLabel = (code: Currency) => {
  return currencies.find((c) => c.value === code)?.label;
};

export type ModelAssetRecipe = {
  asset: ModelAsset;
  price: number;
  targetSize: number;
  allocatedSize: number;
  allocatedCount: number;
};

export function calculateAllocation(
  freeSize: number,
  list: ModelAssetRecipe[],
  rate: number,
) {
  return list
    .filter((i) => i.price > 0)
    .reduce(
      (r, { asset, price, targetSize, allocatedSize, allocatedCount }) => {
        const reviseSize = Math.floor(targetSize * rate - allocatedSize);
        const adjustCount = Math.floor(
          Math.min(reviseSize, r?.freeSize ?? 0) / price,
        );

        const adjustSize = price * adjustCount;
        r.freeSize = (r?.freeSize ?? 0) - price * adjustCount;
        r.list.push({
          asset,
          price,
          targetSize,
          allocatedSize: allocatedSize + adjustSize,
          allocatedCount: allocatedCount + adjustCount,
        });
        return r;
      },
      { list: [], freeSize } as {
        list: ModelAssetRecipe[];
        freeSize: number;
      },
    );
}

export function generate(size: number, models: ModelAssetRecipe[]) {
  let allocation = calculateAllocation(size, models, 1);
  if (allocation.freeSize / size >= 0.05) {
    allocation = calculateAllocation(
      allocation.freeSize,
      orderBy(
        allocation.list,
        [(a) => a.allocatedSize / a.targetSize],
        ['asc'],
      ),
      1 + allocation.freeSize / size,
    );
  }

  return {
    totalSize: size,
    portfolio: orderBy(
      allocation.list,
      [(a) => models.findIndex((m) => m.asset.ticker === a.asset.ticker)],
      ['asc'],
    ).map(({ asset, price, allocatedSize, allocatedCount }) => {
      return {
        asset,
        price,
        size: allocatedSize,
        count: allocatedCount,
      };
    }),
    freeSize: allocation.freeSize,
  } as SimulationRecipe;
}

AssetAllocationSimulator.defaultProps = {
  onUpdate: undefined,
};

function AssetAllocationSimulator({
  assets,
  priceHistory,
  assetSize,
  currency,
  onUpdate,
  ...stackProps
}: AssetAllocationSimulatorProps & StackProps) {
  const result = useMemo(() => {
    // 기본 자산들의 자산단위에서 몇개까지 살 수 있는지 체크
    const result = generate(
      assetSize,
      assets?.map((asset) => {
        const price = last(priceHistory[asset.id]?.priceList)?.close ?? 0;
        return {
          asset,
          price,
          targetSize: assetSize * asset.ratio,
          allocatedSize: 0,
          allocatedCount: 0,
        };
      }) ?? [],
    );
    remove(result.portfolio, (p) => p.count < 1);
    return result;
  }, [assetSize, priceHistory]);

  useEffect(() => {
    onUpdate?.(result);
  }, [result]);

  return (
    <Stack {...stackProps} direction="column" spacing={2}>
      <Stack
        direction="row"
        spacing="16px"
        p="16px"
        sx={{
          backgroundColor: 'background.grey',
          boxShadow:
            '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
        }}
      >
        <Stack flex={1} direction="column">
          <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" flex={1}>
            예상 투자 금액
          </Typography>
          <Typography fontWeight="bold">
            {assetSize.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
            {currency}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack flex={1} direction="column">
          <Typography color="rgba(0, 0, 0, 0.6)" flex={1}>
            예상 매수 총액
          </Typography>
          <Typography fontWeight="bold">
            {(assetSize - (result.freeSize ?? 0)).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{' '}
            {currency}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack flex={1} direction="column">
          <Typography color="rgba(0, 0, 0, 0.6)" flex={1}>
            예상 예수금
          </Typography>
          <Typography fontWeight="bold">
            {(result.freeSize ?? 0).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{' '}
            {currency}
          </Typography>
        </Stack>
      </Stack>
      <TableContainer sx={{ borderRadius: '8px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                '> th': { backgroundColor: '#FAFAFA', fontSize: '12px' },
              }}
            >
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                티커
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                종목명
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                비중
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                수량
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                예상 매수 금액
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.portfolio.map((r) => {
              return (
                <TableRow hover key={r.asset.id}>
                  <TableCell align="left">{r.asset.ticker}</TableCell>
                  <TableCell align="left">{r.asset.name}</TableCell>
                  <TableCell align="right">
                    {((r.size / assetSize) * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell align="right">{r.count}</TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>
                      {(r.price * r.count).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </span>{' '}
                    {currency}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default AssetAllocationSimulator;
