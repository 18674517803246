import { useContext, useMemo } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tooltip,
  chakra,
  HStack,
  ChakraProvider,
} from '@chakra-ui/react';
import { useAmplitude } from 'react-amplitude-hooks';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useTable, Column, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useHistory } from 'react-router';

import { Body2, Caption } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { SortData } from '~/api/screener';
import { FactorStateContext } from '~/containers/factor/context';
// import { addCriticalActionsCountAmplitude } from '~/utils/logger';
// import { SortData } from '~/models/exeScreenerBody';

type Props = {
  columns: Array<Column>;
  data: Array<any>;
  orderByFactors: SortData[];
  updateSortData?: (factorId: number) => void;
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

function MappedTable({ columns, data, orderByFactors, updateSortData }: Props) {
  const history = useHistory();
  if (data.length === 0) {
    return <div> EMPTY</div>;
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        /* @ts-ignore */
        columns: useMemo<Column[]>(
          () =>
            /* @ts-ignore */
            columns,
          [],
        ),
        data,
      },

      useSticky,
      useSortBy,
    );

  const themeData = getThemeData();
  const [t] = useTranslation();
  const { amplitudeInstance, logEvent } = useAmplitude();
  const factorState = useContext(FactorStateContext);

  return (
    <Box w="100%" h="100%" overflow="hidden">
      <ChakraProvider>
        <Table
          {...getTableProps()}
          display="inherit"
          h="100%"
          overflow="scroll"
          style={{ borderCollapse: 'separate' }}
        >
          <Thead
            position="sticky"
            top={0}
            zIndex={10}
            bg={themeData.colors.gray[100]}
            boxShadow={`0 2px ${themeData.colors.gray[200]}`}
          >
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const orderByFactorIndex =
                    orderByFactors?.findIndex(
                      (v) => v?.factorId === column.Header,
                    ) ?? -1;
                  return column.Header === 'cosmosCode' ||
                    column.Header === 'ticker' ||
                    column.Header === 'marketDate' ? null : (
                    <Tooltip
                      color="white"
                      box-shadow="0px 3px 3px #ccc"
                      whiteSpace="pre-line"
                      // @ts-ignore
                      label={t(`factor.${column.Header}.description`)}
                    >
                      <Th
                        title={undefined}
                        style={{
                          ...column.getHeaderProps().style,
                          ...{
                            zIndex: column.Header === 'company' ? 15 : 10,
                            fontSize: '14px',
                            fontWeight: 'normal',
                            color: themeData.colors.text2,
                            background: themeData.colors.gray[100],
                          },
                        }}
                        noOfLines={1}
                        display="table-cell"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        maxW="40em"
                        // @ts-ignore
                        isNumeric={column.isNumeric}
                        onClick={
                          updateSortData
                            ? () => {
                                if (
                                  column.Header === 'company' ||
                                  column.Header === 'marketPrice' ||
                                  column.Header === 'F_SCORE' ||
                                  column.Header === 'R_SCORE'
                                ) {
                                  return;
                                }
                                updateSortData(column.Header as number);
                              }
                            : undefined
                        }
                      >
                        <HStack>
                          <Body2 whiteSpace="nowrap">
                            {/* @ts-ignore */}
                            {`${t(`factor.${column.Header}.name`)}${
                              column.Header === 'F_SCORE'
                                ? `(${factorState?.selectedFactors.size}점 만점)`
                                : ''
                            } `}
                          </Body2>

                          <chakra.span>
                            {orderByFactorIndex >= 0 ? (
                              orderByFactors?.[orderByFactorIndex]?.order ===
                              'DESC' ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              )
                            ) : null}
                          </chakra.span>
                        </HStack>
                      </Th>
                    </Tooltip>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            {...getTableBodyProps()}
            position="relative"
            zIndex={0}
            bg="white"
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (
                      cell.column.Header === 'ticker' ||
                      cell.column.Header === 'cosmosCode' ||
                      cell.column.Header === 'marketDate'
                    ) {
                      return null;
                    }
                    return (
                      <Tooltip
                        placement="right"
                        whiteSpace="pre-line"
                        label={
                          cell.column.Header !== 'marketPrice'
                            ? null
                            : `${row.values['marketDate']} 종가`
                        }
                      >
                        <Td
                          {...cell.getCellProps()}
                          p="6px 24px"
                          borderRight={
                            cell.column.Header === 'company'
                              ? `solid 3px ${themeData.colors.gray[200]}`
                              : 'none'
                          }
                          style={{
                            ...{
                              zIndex: cell.column.Header === 'company' ? 9 : 0,
                              width:
                                cell.column.Header === 'company'
                                  ? '300px'
                                  : undefined,
                              background: 'white',
                              fontSize: '12px',
                            },
                            ...cell.getCellProps().style,
                          }}
                        >
                          {cell.column.Header === 'company' ? (
                            <Box
                              _hover={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                logEvent('screener results company clicked', {
                                  'clicked company': row.values['ticker'],
                                });
                                // addCriticalActionsCountAmplitude({
                                //   amplitudeInstance,
                                // });
                                // history.push(
                                //   `/company-detail/${row.values['cosmosCode']}`,
                                // );
                                window.open(
                                  `/company-detail/${row.values['cosmosCode']}`,
                                  `company-detail-${row.values['cosmosCode']}`,
                                );
                              }}
                            >
                              <Body2
                                whiteSpace="nowrap"
                                color={themeData.colors.primary[500]}
                              >
                                {row.values['ticker']}
                              </Body2>
                              <Caption
                                color={themeData.colors.gray[800]}
                                noOfLines={3}
                              >
                                {cell.render('Cell')}
                              </Caption>
                            </Box>
                          ) : (
                            <Body2
                              whiteSpace="nowrap"
                              color={themeData.colors.text1}
                              align="end"
                            >
                              {cell.render('Cell')}
                            </Body2>
                          )}
                        </Td>
                      </Tooltip>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </ChakraProvider>
    </Box>
  );
}
MappedTable.defaultProps = {
  updateSortData: undefined,
};
export default MappedTable;
