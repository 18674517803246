import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  MoreVert,
  Refresh,
  Close as CloseIcon,
  Public as PublicIcon,
  RemoveCircle,
  AddBox,
  Factory,
  DeleteOutline,
} from '@mui/icons-material';
import { findLast, groupBy, isNil, last, map, uniq, sum } from 'lodash';
import { useTranslation } from 'react-i18next';

import dayjs from '~/utils/dayjs';
import api from '~/api';
import { AppContext } from '~/AppContext';
import { Portfolio } from '~/api/portfolio';
import { Category, CompanyPricesResult, Strategy } from '~/api/strategy';
import StyledModal from '~/components/StyledModal';
import StyledButton from '~/components/StyledButton';
import { convertFactorFilterToQuery } from '~/api/backtesting';

export default function PortfolioListView() {
  const [t] = useTranslation();
  const { state } = useContext(AppContext) ?? {};
  const history = useHistory();

  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
  const [openPortfolioMenuState, setOpenPortfolioMenuState] = useState<null | {
    anchorEl: HTMLElement;
    portfolioId: string;
  }>(null);
  const [openDetailStrategy, setOpenDetailStrategy] = useState<Strategy | null>(
    null,
  );
  const [isOpenConfirmDeletePortfolioId, setIsOpenConfirmDeletePortfolioId] =
    useState<string | null>(null);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

  const requestPortfolios = () => {
    api.portfolio.portfolios().then(
      (response) => {
        return setPortfolios(response.data);
      },
      () => {
        console.log('fail');
      },
    );
  };

  const requestUpdatePerformance = (portfolioId: string) => {
    setIsLoadingPrices(true);
    (async () => {
      await api.portfolio.updatePortfolioPerformance(portfolioId);
      requestPortfolios();
    })()
      .catch((error) => {
        console.log('fail', error);
      })
      .finally(() => {
        setIsLoadingPrices(false);
      });
  };

  const handleCloseMenu = () => {
    setOpenPortfolioMenuState(null);
  };

  const closePortfolio = (id: string) => {
    api.portfolio.closePortfolio(id).then(
      () => {
        requestPortfolios();
      },
      (error) => {
        console.log('fail', error);
      },
    );
  };

  useEffect(() => {
    if (state?.user) {
      api.strategy.getSectors().then((response) => {
        setCategoryList(response.data);
      });
      requestPortfolios();
    }
  }, [state?.user]);

  const strategyDetailModal = useMemo(() => {
    if (!openDetailStrategy) {
      return null;
    }

    const appliedFactors = [
      ...(openDetailStrategy?.factorQuery ?? []),
      ...convertFactorFilterToQuery(openDetailStrategy.factorFilter),
    ];

    const appliedFactorCount = sum(
      appliedFactors.map((f) => [f.gt, f.lt].filter((v) => !isNil(v)).length),
    );

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={!!openDetailStrategy}
        onClose={() => setOpenDetailStrategy(null)}
      >
        <Stack
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            overflow: 'auto',
            minWidth: [null, '600px'],
            width: ['100%', null],
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          spacing="8px"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="headline1" flex={1}>
              전략 세부정보
            </Typography>
            <IconButton
              size="small"
              onClick={() => setOpenDetailStrategy(null)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Refresh />
            <Typography variant="subtitle2" flex={1}>
              리밸런싱 주기
            </Typography>
            <Typography>
              {openDetailStrategy?.rebalancingPeriod
                ? t(`period.${openDetailStrategy?.rebalancingPeriod}`)
                : '없음'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <PublicIcon />
            <Typography variant="subtitle2" flex={1}>
              선택한 시장
            </Typography>
            {openDetailStrategy.indexCode ? (
              <Typography variant="body2">
                {t(`index.${openDetailStrategy.indexCode}`)}
              </Typography>
            ) : (
              <Typography variant="body2">
                {t(`nation.${openDetailStrategy.nationCode}.flag`)}{' '}
                {t(`nation.${openDetailStrategy.nationCode}.name`)}
              </Typography>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Factory />
            <Typography variant="subtitle2" flex={1}>
              선택한 산업
            </Typography>
            <Typography>
              {(openDetailStrategy.categoryIds?.length ?? 0) > 0
                ? `${openDetailStrategy.categoryIds?.length}개`
                : '전체'}
            </Typography>
          </Stack>
          {(openDetailStrategy.categoryIds?.length ?? 0) > 0 ? (
            <Stack maxWidth="500px" direction="row" flexWrap="wrap">
              {openDetailStrategy.categoryIds?.map((categoryId) => {
                const category = findLast(categoryList, (c) =>
                  categoryId.toString().startsWith(c.cosmosGroupId.toString()),
                );
                if (!category) {
                  return null;
                }
                return (
                  <Box
                    sx={{ background: '#E5E7EB' }}
                    key={category.cosmosGroupId}
                    mt="8px"
                    ml="8px"
                    p="4px"
                    borderRadius="4px"
                    width="fit-content"
                  >
                    <Typography variant="body2" color="#677380">
                      {t(`category.${category.name}`)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          ) : null}
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <AddBox />
            <Typography variant="subtitle2" flex={1}>
              종목 선정 조건
            </Typography>
            <Typography>
              {appliedFactorCount > 0 ? `${appliedFactors.length}개` : '없음'}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {map(appliedFactors, (condition) => {
              return (
                <Typography
                  key={condition.factorId}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {!isNil(condition.gt)
                    ? `${condition.gt} ${condition.gte ? '<=' : '<'} `
                    : null}
                  {t(`factor.${condition.factorId}.name`)}
                  {!isNil(condition.lt)
                    ? ` ${condition.lte ? '<=' : '<'} ${condition.lt}`
                    : null}
                </Typography>
              );
            })}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <RemoveCircle />
            <Typography variant="subtitle2" flex={1}>
              투자 제외 종목
            </Typography>
            <Typography>
              {(openDetailStrategy?.excludedCompanies?.length ?? 0) > 0
                ? `${openDetailStrategy?.excludedCompanies?.length}개`
                : '없음'}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {openDetailStrategy?.excludedCompanies?.map?.((company) => {
              return (
                <Typography
                  key={company.id}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {company.name}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [openDetailStrategy]);

  const portfolioTable = (
    <TableContainer
      sx={{
        mt: '36px',
        flex: 1,
      }}
    >
      <Table stickyHeader sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow
            sx={{
              '> th': { backgroundColor: '#FAFAFA' },
            }}
          >
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              생성일
            </TableCell>
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              이름
            </TableCell>
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              설정한 투자 전략
            </TableCell>
            <TableCell align="right" sx={{ typography: 'subtitle2' }}>
              수익률
            </TableCell>
            <TableCell align="center" />

            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolios.length < 1 ? (
            <TableRow>
              <TableCell
                align="center"
                colSpan={12}
                sx={{
                  height: '240px',
                  typography: 'body',
                  color: '#00000061',
                }}
              >
                먼저 전략을 생성해 주세요.
              </TableCell>
            </TableRow>
          ) : null}
          {portfolios.map((portfolio) => (
            <TableRow
              key={portfolio.id}
              sx={
                !portfolio.strategy
                  ? {
                      '> td': { backgroundColor: '#FAFAFA' },
                    }
                  : {}
              }
            >
              <TableCell>
                {dayjs(portfolio.createdAt).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell align="left">{portfolio.name}</TableCell>
              <TableCell
                align="left"
                onClick={() =>
                  portfolio?.strategy &&
                  setOpenDetailStrategy(portfolio.strategy)
                }
              >
                {portfolio?.strategy ? (
                  <Typography variant="body1" whiteSpace="pre">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      underline="always"
                      onClick={() =>
                        portfolio?.strategy &&
                        setOpenDetailStrategy(portfolio.strategy)
                      }
                    >
                      {portfolio.strategy?.name ?? '-'}
                    </Link>
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    color="text.disabled"
                    whiteSpace="pre"
                  >
                    운용중인 투자가 없습니다.
                  </Typography>
                )}
                {portfolio.strategy?.rebalancingPeriod ? (
                  <Typography variant="body2">
                    포트폴리오 리밸런싱 주기:{' '}
                    {t(`period.${portfolio.strategy?.rebalancingPeriod}`)}
                  </Typography>
                ) : null}
              </TableCell>
              <TableCell align="right">
                <Stack alignItems="end" spacing={1}>
                  {dayjs(portfolio.createdAt).isSame(dayjs(), 'day') ? (
                    <Typography color="text.disabled">
                      내일부터 표기됩니다.
                    </Typography>
                  ) : (
                    <>
                      <Stack direction="row" alignItems="center">
                        {!isNil(portfolio.yield) ? (
                          <Typography
                            variant="body1"
                            color={
                              (portfolio?.yield ?? 0) > 0
                                ? 'red'
                                : (portfolio?.yield ?? 0) < 0
                                ? 'blue'
                                : 'black'
                            }
                          >
                            {(portfolio?.yield ?? 0) > 0 ? '+' : null}
                            {`${(portfolio.yield * 100).toFixed(2)}%`}
                          </Typography>
                        ) : null}
                        {portfolio.strategy ? (
                          <IconButton
                            size="small"
                            sx={{
                              width: '16px',
                              height: '16px',
                              '& svg': {
                                fontSize: '16px',
                              },
                            }}
                            onClick={() => {
                              if (!isLoadingPrices) {
                                requestUpdatePerformance(portfolio.id);
                              }
                            }}
                          >
                            <Refresh />
                          </IconButton>
                        ) : null}
                      </Stack>
                      {portfolio.performanceUpdatedAt ? (
                        <Typography color="text.disabled">
                          update:{' '}
                          {dayjs(portfolio.performanceUpdatedAt).format(
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </Typography>
                      ) : null}
                    </>
                  )}
                </Stack>
              </TableCell>
              <TableCell align="center">
                {portfolio.strategy ? (
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    onClick={() => history.push(`/portfolio/${portfolio.uid}`)}
                  >
                    상세 내용 확인하기
                  </StyledButton>
                ) : (
                  '-'
                )}
              </TableCell>
              {/* <TableCell align="left">
            {portfolio.account ? (
              <Typography>{portfolio.account.provider}</Typography>
            ) : null}
          </TableCell> */}
              <TableCell align="right" width="30px">
                <IconButton
                  aria-label="more"
                  aria-controls={
                    openPortfolioMenuState?.portfolioId === portfolio.id
                      ? 'long-menu'
                      : undefined
                  }
                  aria-expanded={
                    openPortfolioMenuState?.portfolioId === portfolio.id
                      ? 'true'
                      : undefined
                  }
                  aria-haspopup="true"
                  onClick={(e) => {
                    setOpenPortfolioMenuState({
                      anchorEl: e.currentTarget,
                      portfolioId: portfolio.id,
                    });
                  }}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={openPortfolioMenuState?.anchorEl}
                  open={openPortfolioMenuState?.portfolioId === portfolio.id}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      setIsOpenConfirmDeletePortfolioId(portfolio.id)
                    }
                  >
                    <ListItemIcon>
                      <DeleteOutline color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ color: 'error' }}
                      primary="삭제"
                    />
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  function MobilePortfolioItem({ portfolio }: { portfolio: Portfolio }) {
    return (
      <Grid
        container
        columns={6}
        rowSpacing={2}
        py="24px"
        borderBottom="1px solid rgba(0, 0, 0, 0.08)"
      >
        <Grid item xs={2}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            포트폴리오 이름
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2" fontSize="14px">
            {portfolio.name}
          </Typography>
          <Typography
            variant="caption"
            color="rgba(0, 0, 0, 0.38);"
          >{`생성일 : ${dayjs(portfolio.createdAt).format(
            'YYYY-MM-DD',
          )}`}</Typography>

          <Stack direction="row" mt="8px" spacing="12px">
            <Button
              variant="outlined"
              onClick={() => history.push(`/portfolio/${portfolio.uid}`)}
            >
              <Typography variant="body2">포트폴리오 보기</Typography>
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setIsOpenConfirmDeletePortfolioId(portfolio.id);
              }}
            >
              삭제
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            설정한 투자 전략
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" whiteSpace="pre">
            {portfolio.strategy?.name ?? '-'}
          </Typography>

          <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
            포트폴리오 리밸런싱 주기:{' '}
            {t(`period.${portfolio.strategy?.rebalancingPeriod}`)}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              marginTop: '8px',
              fontSize: '12px',
            }}
            onClick={() => {
              if (portfolio.strategy) {
                setOpenDetailStrategy(portfolio.strategy);
              }
            }}
          >
            전략 세부정보 보기
          </Button>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            수익률
          </Typography>
        </Grid>

        <Grid item xs={4}>
          {dayjs(portfolio.createdAt).isSame(dayjs(), 'day') ? (
            <Typography color="text.disabled">내일부터 표기됩니다.</Typography>
          ) : (
            <>
              <Stack direction="row" alignItems="center">
                {!isNil(portfolio.yield) ? (
                  <Typography
                    variant="body1"
                    color={
                      (portfolio?.yield ?? 0) > 0
                        ? 'red'
                        : (portfolio?.yield ?? 0) < 0
                        ? 'blue'
                        : 'black'
                    }
                  >
                    {(portfolio?.yield ?? 0) > 0 ? '+' : null}
                    {`${(portfolio.yield * 100).toFixed(2)}%`}
                  </Typography>
                ) : null}
                {portfolio.strategy ? (
                  <IconButton
                    size="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      '& svg': {
                        fontSize: '16px',
                      },
                    }}
                    onClick={() => {
                      if (!isLoadingPrices) {
                        requestUpdatePerformance(portfolio.id);
                      }
                    }}
                  >
                    <Refresh />
                  </IconButton>
                ) : null}
              </Stack>
              {portfolio.performanceUpdatedAt ? (
                <Typography color="text.disabled">
                  update:{' '}
                  {dayjs(portfolio.performanceUpdatedAt).format(
                    'YYYY/MM/DD HH:mm:ss',
                  )}
                </Typography>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    );
  }
  const mobilePortfolioListView = (
    <Box>
      {portfolios.length < 1 ? (
        <Box
        // align="center"
        // sx={{
        //   height: '240px',
        //   typography: 'body',
        //   color: '#00000061',
        // }}
        >
          먼저 전략을 생성해 주세요.
        </Box>
      ) : (
        portfolios.map((portfolio) => (
          <MobilePortfolioItem key={portfolio.uid} portfolio={portfolio} />
        ))
      )}
    </Box>
  );

  return (
    <Stack
      width="100%"
      height="100%"
      overflow="hidden"
      direction="column"
      alignItems="center"
    >
      <Stack
        px="16px"
        direction="column"
        maxWidth="1176px"
        width="100%"
        height="-webkit-fill-available"
        // height="100%"
        paddingTop="12px"
      >
        <Stack width="100%" direction="row" alignItems="center" spacing="8px">
          <Typography variant="headline1" mt="16px">
            내 포트폴리오
          </Typography>
        </Stack>

        <Box display={['none', 'inherit']} overflow="scroll">
          {portfolioTable}
        </Box>
        <Box display={['inherit', 'none']} height="100%" overflow="scroll">
          {mobilePortfolioListView}
        </Box>

        {strategyDetailModal}
        {isOpenConfirmDeletePortfolioId !== null ? (
          <Dialog fullWidth open={isOpenConfirmDeletePortfolioId !== null}>
            <DialogTitle>
              <DialogContentText>이 포트폴리오를 삭제하나요?</DialogContentText>
            </DialogTitle>
            <DialogActions>
              <StyledButton
                onClick={() => setIsOpenConfirmDeletePortfolioId(null)}
              >
                취소
              </StyledButton>
              <StyledButton
                color="error"
                variant="contained"
                onClick={() => {
                  closePortfolio(isOpenConfirmDeletePortfolioId);
                  setIsOpenConfirmDeletePortfolioId(null);
                }}
              >
                삭제
              </StyledButton>
            </DialogActions>
          </Dialog>
        ) : null}
      </Stack>
    </Stack>
  );
}
