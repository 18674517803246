import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  StackProps,
  Step,
  StepLabel,
  Stepper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Alert, LoadingButton } from '@mui/lab';
import {
  clamp,
  cloneDeep,
  floor,
  groupBy,
  isNil,
  last,
  sortBy,
  sum,
  takeRight,
  throttle,
  uniq,
  uniqBy,
} from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import dayjs from '~/utils/dayjs';
import {
  Portfolio,
  Account,
  AssetRecipe,
  AccountBalance,
  ModelAsset,
  Positioning,
  StockPrice,
  PositioningPhase,
  ExecutionStatus,
  StockOrder,
  OrderableState,
  nationCurrencies,
} from '~/api/portfolio';
import api, { CurrencyRates, Currency } from '~/api';
import LottieAnimation from '~/components/LottieAnimation';
import lottieLoading from '~/assets/lottie/lottie_loading.json';
import useInterval from '~/hooks/useInterval';
import CustomStepper from '~/components/CustomStepper';
import { ReactComponent as CircleRefresh } from '~/assets/icon/circle_refresh.svg';
import BufferRateTextField from '~/components/portfolio/BufferRateTextField';

type PortfolioPositioningProps = {
  portfolio: Portfolio;
  account: Account;
  onClose?: () => void;
  onAllocated?: (orders: AssetRecipe[]) => void;
} & StackProps;

function toTitle(phase: PositioningPhase) {
  return (
    {
      simulating: '리밸런싱 계획',
      sellPlanning: '매도 주문',
      selling: '매도중',
      buyPlanning: '매수 주문',
      buying: '매수중',
      done: '완료',
    } as { [key in PositioningPhase]: string }
  )[phase ?? 'simulating'];
}

// 보정 이후 가격을 보정... 음...
function correctTradingPriceUnit(
  market: string,
  price: number,
  integerFunction: (x: number) => number,
  [min, max] = [0, Number.MAX_SAFE_INTEGER],
  defaultUnit = 1,
) {
  let unit = defaultUnit;
  if (
    (/^KOSPI/i.test(market) || /^K(OSDAQ|SQ)/i.test(market)) &&
    dayjs('2023-01-01 00:00:00').tz('Asia/seoul', true).isSameOrBefore(dayjs())
  ) {
    // 2023년 1월 1일 KST 부터 코스피, 코스닥 호가정책 적용
    unit =
      [
        [2000, 1],
        [5000, 5],
        [20000, 10],
        [50000, 50],
        [200000, 100],
        [500000, 500],
        [Number.MAX_SAFE_INTEGER, 1000],
      ].find(([limit]) => price < limit)?.[1] ?? defaultUnit;
  } else if (/^KOSPI/i.test(market)) {
    // 코스피
    unit =
      [
        [1000, 1],
        [5000, 5],
        [10000, 10],
        [50000, 50],
        [100000, 100],
        [500000, 500],
        [Number.MAX_SAFE_INTEGER, 1000],
      ].find(([limit]) => price < limit)?.[1] ?? defaultUnit;
  } else if (/^K(OSDAQ|SQ)/i.test(market)) {
    // 코스닥
    unit =
      [
        [1000, 1],
        [5000, 5],
        [10000, 10],
        [50000, 50],
        [Number.MAX_SAFE_INTEGER, 100],
      ].find(([limit]) => price < limit)?.[1] ?? defaultUnit;
  }

  return clamp(integerFunction(price / unit) * unit, min, max);
}

function checkMarketTradeableTime(marketName: string, date = new Date()) {
  return true;
}

function compareBalanceWithModel(
  modelAssets: ModelAsset[],
  balance: AccountBalance,
) {
  return [
    ...modelAssets.map((modelAsset) => {
      const realAsset = balance.assets.find(
        (asset) => asset.ticker === modelAsset.ticker,
      );
      const realRatio =
        balance.totalAmount > 0
          ? (realAsset?.evaluatedAmount ?? 0) / balance.totalAmount
          : 0;

      return {
        ticker: modelAsset.ticker,
        name: realAsset?.name ?? modelAsset.name,
        currency: realAsset?.currency ?? modelAsset.currency,
        modelAsset,
        realAsset: realAsset ?? null,
        currentRatio: realRatio,
        currentAmount: realAsset?.evaluatedAmount ?? 0,
        targetRatio: modelAsset.ratio,
        targetAmount: balance.totalAmount * modelAsset.ratio,
      };
    }),
    ...balance.assets
      .filter((a) => !modelAssets.some((m) => m.ticker === a.ticker))
      .map((realAsset) => {
        const realRatio =
          balance.totalAmount > 0
            ? realAsset.evaluatedAmount / balance.totalAmount
            : 0;
        return {
          ticker: realAsset.ticker,
          name: realAsset.name,
          currency: realAsset.currency,
          modelAsset: null,
          realAsset,
          currentRatio: realRatio,
          currentAmount: realAsset.evaluatedAmount,
          targetRatio: 0,
          targetAmount: 0,
        };
      }),
  ];
}

const PortfolioPositioning = forwardRef(
  (
    {
      portfolio,
      account,
      onClose,
      onAllocated,
      ...stackProps
    }: PortfolioPositioningProps,
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [positioning, setPositioning] = useState<Positioning | null>(null);

    const [currentPrices, setCurrentPrices] = useState<{
      [ticker: string]: StockPrice;
    }>();

    const [realBalance, setRealBalance] = useState<AccountBalance | null>(null);
    const [orderable, setOrderable] = useState<OrderableState | null>(null);

    const [notificationMessage, setNotificationMessage] = useState<
      string | null
    >(null);

    const [currency, setCurrency] = useState<Currency>('KRW');
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates | null>(
      null,
    );

    const [bufferRate, setBufferRate] = useState<number>(0.01);

    const [modelAssets, setModelAssets] = useState<ModelAsset[] | null>(null);

    const isLoadingPricesError = useMemo(() => {
      return currentPrices && Object.keys(currentPrices).some((v) => isNil(v));
    }, [currentPrices]);

    const phase = useMemo<
      'simulating' | 'sellPlanning' | 'selling' | 'buyPlanning' | 'buying'
    >(() => {
      if (
        !positioning ||
        positioning?.phase === 'simulating' ||
        positioning?.phase === 'done'
      ) {
        return 'simulating';
      }
      return positioning?.phase ?? 'simulating';
    }, [positioning]);

    const createPositioning = (
      portfolioId: string,
      currency: string,
      phase: PositioningPhase,
      modelAssets: ModelAsset[],
      orders?: StockOrder[],
    ) => {
      setIsLoading(true);
      api.portfolio
        .createPositioning(portfolioId, {
          currency,
          phase,
          modelAssets,
          orders,
        })
        .then(async (res) => {
          const posRes = await api.portfolio.latestPositioning(portfolioId);
          setPositioning(posRes.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setIsLoading(false));
    };

    const throttledExecutePositioning = useMemo(() => {
      return throttle(
        (
          portfolioId: string,
          modelAssets: ModelAsset[],
          orders: StockOrder[],
        ) => {
          setIsLoading(true);
          api.portfolio
            .executePositioning(portfolioId, {
              orders,
            })
            .then(async (res) => {
              const [posRes, balRes] = await Promise.all([
                api.portfolio.latestPositioning(portfolioId),
                api.portfolio.realBalance(portfolio.id, currency),
              ]);
              setRealBalance(balRes.data);
              setPositioning(posRes.data);

              const errorExecution = takeRight(
                posRes?.data?.executions,
                orders.length,
              )?.find((e) => e.status === 'failed');
              if (errorExecution) {
                setNotificationMessage(
                  errorExecution.errorMessage ??
                    '주문중 오류가 발생하였습니다.',
                );
              }
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => setIsLoading(false));
        },
        1000,
      );
    }, [currency]);

    const throttledChangePhase = useMemo(() => {
      return throttle((portfolioId: string, phase: PositioningPhase) => {
        setIsLoading(true);
        api.portfolio
          .changePositioningPhase(portfolioId, phase)
          .then(async () => {
            if (phase === 'done' && onClose) {
              onClose?.();
              return;
            }
            const res = await api.portfolio.latestPositioning(portfolioId);

            if (phase === 'sellPlanning' || phase === 'buyPlanning') {
              const orderableRes = await api.portfolio.orderable(
                portfolioId,
                currency,
              );

              const pricesRes = await api.portfolio.realStockPrice(
                portfolio?.id,
                uniqBy(
                  [
                    ...(res.data?.modelAssets?.map((a) => ({
                      exchange: a.exchange ?? 'KRX',
                      ticker: a.ticker,
                    })) ?? []),
                    ...(res.data?.realAssets?.map((ra) => ({
                      exchange: ra.exchange ?? 'KRX',
                      ticker: ra.ticker,
                    })) ?? []),
                  ],
                  (v) => `${v.exchange}:${v.ticker}`,
                ),
              );
              setOrderable(orderableRes.data);
              setCurrentPrices((state) => ({
                ...state,
                ...pricesRes.data,
              }));
            }
            setPositioning(res.data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    }, [currency]);

    const throttledCancelExecutions = useMemo(() => {
      return throttle((portfolioId: string) => {
        setIsLoading(true);
        // 기존 포지션이 있으면 업데이트로 쳐야한다
        api.portfolio
          .cancelExecutions(portfolioId)
          .catch((e) => {
            console.log(e);
          })
          .then((res) => {
            throttledUpdatePosition(portfolio.id);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    }, []);

    const throttledUpdatePosition = useMemo(() => {
      return throttle((portfolioId: string) => {
        setIsSyncing(true);
        Promise.all([
          api.portfolio.syncPositioning(portfolioId, currency).then((res) => {
            setPositioning(res.data);
          }),
          api.portfolio.realBalance(portfolio.id, currency).then((res) => {
            setRealBalance(res.data);
          }),
        ])
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setIsSyncing(false);
          });
      }, 1000);
    }, [currency]);

    useEffect(() => {
      setIsLoading(true);
      api.currencyRatesBaseUSD().then(
        (response) => {
          setCurrencyRates(response.data.rates);
        },
        (error) => {
          console.log('fail', error);
        },
      );
      (async () => {
        let targetCurrency =
          portfolio?.strategy?.nationCode &&
          portfolio?.strategy.nationCode in nationCurrencies
            ? nationCurrencies[portfolio?.strategy.nationCode]
            : undefined;

        const positioningRes = await api.portfolio.latestPositioning(
          portfolio.id,
        );

        // 진행중인게 없다면
        let modelAssets: ModelAsset[];
        if (!positioningRes.data || positioningRes.data.phase === 'done') {
          modelAssets = portfolio.strategy
            ? (await api.strategy.getStrategyCompanies(portfolio.strategy.id))
                .data
            : portfolio?.modelAllocation?.assets;
        } else {
          // 기존거 참고
          modelAssets =
            positioningRes.data.modelAssets ??
            portfolio?.modelAllocation?.assets;
        }

        const sampleCode = modelAssets[0]?.id;

        // 현재 밸런스
        // 연동정보? <- 이건 결국 account에 있어야하는건가?
        // 현재가
        if (!targetCurrency) {
          const samplePriceRes = await api.strategy
            .getCompanyPriceHistory(
              sampleCode,
              dayjs().subtract(7, 'day').toDate(),
              new Date(),
            )
            .catch((err) => {
              console.log('fail price', err);
              return null;
            });
          targetCurrency = samplePriceRes?.data?.currency;
        }

        const [realBalanceRes, orderableRes] = await Promise.all([
          api.portfolio.realBalance(portfolio.id, targetCurrency),
          api.portfolio.orderable(portfolio.id, targetCurrency),
        ]);

        const pricesRes = await api.portfolio.realStockPrice(
          portfolio?.id,
          uniqBy(
            [
              ...(modelAssets?.map((asset) => ({
                exchange: asset.exchange ?? 'KRX',
                ticker: asset.ticker,
              })) ?? []),
              ...(realBalanceRes?.data?.assets?.map((ra) => ({
                exchange: ra.exchange ?? 'KRX',
                ticker: ra.ticker,
              })) ?? []),
            ],
            (v) => `${v.exchange}:${v.ticker}`,
          ),
        );

        setModelAssets(modelAssets);
        setPositioning(positioningRes.data);
        setRealBalance(realBalanceRes.data);
        setOrderable(orderableRes.data);
        setCurrency((targetCurrency ?? 'KRW') as typeof currency);
        setCurrentPrices(pricesRes.data);
      })()
        .catch((error) => {
          console.log('fail', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [portfolio?.strategy]);

    useInterval(
      useCallback(() => {
        if (!portfolio || (phase !== 'selling' && phase !== 'buying')) {
          return;
        }

        if (!isLoading && !isSyncing) {
          throttledUpdatePosition(portfolio.id);
        }
      }, [portfolio, phase, isLoading, isSyncing]),
      useMemo(() => {
        if (
          !portfolio ||
          isLoading ||
          (phase !== 'selling' && phase !== 'buying')
        ) {
          return null;
        }
        return 15 * 1000;
      }, [portfolio, phase, isLoading]),
    );

    const exchangeKrwRate = useMemo(() => {
      if (!currencyRates) {
        return null;
      }
      const usdToKrwRate = currencyRates['KRW'];
      const usdToTargetRate = currencyRates?.[currency as Currency] ?? 1;

      return usdToKrwRate / usdToTargetRate;
    }, [currency, currencyRates]);

    // 기본 자산들의 자산단위에서 몇개까지 살 수 있는지 체크
    const defaultPriceStep = useMemo(
      () => (currency === 'KRW' ? 500 : 0.01),
      [currency],
    );

    const diffStates = useMemo(() => {
      if (!modelAssets || !realBalance) {
        return null;
      }
      return compareBalanceWithModel(modelAssets, realBalance);
    }, [modelAssets, realBalance]);

    const sellRecipes = useMemo(() => {
      return diffStates
        ?.filter((s) => s.targetRatio - s.currentRatio < 0)
        .map((state) => {
          // 가격정보
          const priceInfo = currentPrices?.[state.ticker];
          const currentPrice = priceInfo?.current ?? 0;

          const targetShares =
            currentPrice > 0 ? state.targetAmount / currentPrice : 0;
          const sellPrice = correctTradingPriceUnit(
            priceInfo?.marketName ?? '',
            currentPrice * (1 - bufferRate),
            Math.floor,
            [
              priceInfo?.lowLimit ?? 0,
              priceInfo?.maxLimit ?? Number.MAX_SAFE_INTEGER,
            ],
            priceInfo?.unit ?? defaultPriceStep,
          );

          const adjustShares = Math.max(
            0,
            Math.round(
              (((realBalance?.totalAmount ?? 0) -
                (currentPrice - sellPrice) * targetShares) *
                state.targetRatio) /
                sellPrice,
            ),
          );

          return {
            exchange: priceInfo?.exchange,
            currency: state?.currency,
            ticker: state.ticker,
            name: state.name,
            currentPrice,
            sellPrice,
            currentShares: state?.realAsset?.shares ?? 0,
            sellShares: (state?.realAsset?.shares ?? 0) - adjustShares,
            yesterdayTransactionVolume:
              priceInfo?.yesterdayTransactionVolumeRate
                ? (priceInfo?.transactionVolume ?? 0) /
                  priceInfo.yesterdayTransactionVolumeRate
                : 0,
          };
        })
        ?.filter((r) => r.sellShares > 0);
    }, [bufferRate, diffStates, realBalance, currentPrices, defaultPriceStep]);

    const buyRecipes = useMemo(() => {
      const ESTIMATE_FILL_PRICE_FACTOR = 0.5;
      const recipes = diffStates
        ?.filter((s) => s.targetRatio - s.currentRatio > 0)
        .map((state) => {
          // 가격정보
          const priceInfo = currentPrices?.[state.ticker];
          const currentPrice = priceInfo?.current ?? 0;

          const buyPrice = correctTradingPriceUnit(
            priceInfo?.marketName ?? '',
            (priceInfo?.current ?? 0) * (1 + bufferRate),
            Math.ceil,
            [
              priceInfo?.lowLimit ?? 0,
              priceInfo?.maxLimit ?? Number.MAX_SAFE_INTEGER,
            ],
            priceInfo?.unit ?? defaultPriceStep,
          );

          return {
            exchange: priceInfo?.exchange,
            currency: state?.currency,
            ticker: state.ticker,
            name: state.name,
            targetRatio: state.targetRatio,
            currentPrice,
            buyPrice,
            estimateFillPrice:
              (priceInfo?.current ?? 0) *
              (1 + bufferRate * ESTIMATE_FILL_PRICE_FACTOR),
            currentShares: state?.realAsset?.shares ?? 0,
            buyShares: 0,
            yesterdayTransactionVolume:
              priceInfo?.yesterdayTransactionVolumeRate
                ? (priceInfo?.transactionVolume ?? 0) /
                  priceInfo.yesterdayTransactionVolumeRate
                : 0,
          };
        });

      const estimateAmount =
        (realBalance?.totalAmount ?? 0) +
        sum(
          recipes
            ?.filter(
              (r) =>
                Math.round(
                  ((realBalance?.totalAmount ?? 0) * r.targetRatio) /
                    r.currentPrice,
                ) > r.currentShares,
            )
            .map(
              (r) => (r.estimateFillPrice - r.currentPrice) * r.currentShares,
            ),
        );

      const calculateDiffRate = (
        recipe: NonNullable<typeof recipes>[number],
        addShares = 0,
      ) =>
        (recipe.estimateFillPrice *
          (recipe.currentShares + recipe.buyShares + addShares)) /
          estimateAmount -
        recipe.targetRatio;

      let availableAmount = orderable?.maxOrderableCash ?? 0;
      while (availableAmount > 0) {
        const targetRecipe = sortBy(
          recipes,
          (recipe) => calculateDiffRate(recipe, 1) + calculateDiffRate(recipe),
        ).find(
          (recipe) =>
            recipe.buyPrice > 0 &&
            recipe.buyPrice <= availableAmount &&
            calculateDiffRate(recipe, 1) + calculateDiffRate(recipe) <= 0,
        );

        if (!targetRecipe) {
          break;
        }
        availableAmount -= targetRecipe.buyPrice;
        targetRecipe.buyShares += 1;
      }
      return recipes?.filter((r) => r.buyShares > 0);
    }, [
      bufferRate,
      diffStates,
      realBalance,
      orderable,
      currentPrices,
      defaultPriceStep,
    ]);

    function transOrderStatusText(status?: ExecutionStatus) {
      switch (status) {
        case 'open': {
          return '거래중';
        }
        case 'filled': {
          return '잔고 확인중';
        }
        case 'verified': {
          return '완료';
        }
        case 'canceled': {
          return '취소됨';
        }
        case 'rejected': {
          return '거부됨';
        }
        case 'revised': {
          return '정정됨';
        }
        case 'failed': {
          return '실패';
        }
        default: {
          return '준비중';
        }
      }
    }

    const recommendAmount = useMemo(() => {
      const maxDiffRate = 0.2;
      return (
        Math.ceil(
          Math.max(
            ...(modelAssets?.map((asset) => {
              const priceInfo = currentPrices?.[asset.ticker];
              const currentPrice = priceInfo?.current ?? 0;

              return currentPrice / asset.ratio / maxDiffRate / 2;
            }) ?? []),
          ) / defaultPriceStep,
        ) * defaultPriceStep
      );
    }, [modelAssets, currentPrices, defaultPriceStep]);

    const stepProgress = useMemo(() => {
      if (phase === 'simulating') {
        return null;
      }

      const steps: PositioningPhase[] = [];
      if (
        phase === 'sellPlanning' ||
        positioning?.executions?.some((e) => e.orderType === 'sell')
      ) {
        steps.push('sellPlanning', 'selling');
      }

      if (
        (buyRecipes?.length ?? 0) > 0 ||
        positioning?.executions?.some((e) => e.orderType === 'buy')
      ) {
        steps.push('buyPlanning', 'buying');
      }
      steps.push('done');

      const latestExecutedAt = dayjs.max(
        positioning?.executions?.map((e) => dayjs(e.executedAt)) ?? [],
      );

      let currentStep = steps.findIndex((s) => s === phase);
      if (
        currentStep === steps.length - 2 &&
        sum(
          positioning?.executions
            ?.filter(
              (e) =>
                e.orderNo &&
                e.shares > 0 &&
                (!latestExecutedAt || latestExecutedAt.isSame(e.executedAt)),
            )
            .map((e) => e.remainingShares),
        ) <= 0
      ) {
        currentStep = steps.length - 1;
      }

      return (
        <CustomStepper
          sx={{ flex: 1 }}
          activeStep={currentStep}
          alternativeLabel
          titleList={steps.map((step) => toTitle(step))}
        />
      );
    }, [phase, positioning, buyRecipes, sellRecipes]);

    const simulating = useMemo(() => {
      if (
        phase !== 'simulating' ||
        !diffStates ||
        !modelAssets ||
        !realBalance
      ) {
        return null;
      }

      const diffRates = diffStates.map((d) => d.targetRatio - d.currentRatio);
      const totalDiffRate = sum(diffRates.map((r) => Math.abs(r)));

      return (
        <>
          <Stack
            direction={['column', 'row']}
            p="16px"
            sx={{
              backgroundColor: 'background.grey',
              boxShadow:
                '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
              borderRadius: '8px',
            }}
          >
            <Stack flex={1} direction={['row', 'column']} px="16px">
              <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" flex={1}>
                총 투자금액
              </Typography>
              <Typography fontWeight="bold">
                {realBalance?.totalAmount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: ['none', 'initial'] }}
            />
            <Stack
              flex={1}
              direction={['row', 'column']}
              px="16px"
              pt={['8px', '0px']}
            >
              <Stack flex={1} direction="row" alignItems="center">
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  sx={{
                    verticalAlign: 'center',
                  }}
                >
                  추천 투자 금액
                </Typography>
                <Tooltip
                  title={`포트폴리오 목표 비중을 유사하게 따르기 위해 필요한 금액입니다.\n추천 총 투자 금액보다 적은 금액을 투자하는 것도 가능하지만 특정 종목을 사지 않거나, 목표 비중과 차이가 클 수 있습니다.`}
                  arrow
                  placement="right"
                >
                  <HelpOutlineIcon
                    sx={{
                      height: '16px',
                    }}
                  />
                </Tooltip>
              </Stack>
              <Typography fontWeight="bold">
                {recommendAmount.toLocaleString(undefined, {
                  maximumSignificantDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
          </Stack>
          <TableContainer sx={{ flex: 1, borderRadius: '8px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    '> th': { backgroundColor: '#FAFAFA', fontSize: '12px' },
                  }}
                >
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                    티커
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                    회사명
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    현재 비중
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    목표 비중
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortBy(
                  diffStates,
                  ({ targetRatio, currentRatio }) => targetRatio - currentRatio,
                ).map(({ ticker, name, currentRatio, targetRatio }) => {
                  return (
                    <TableRow hover key={ticker}>
                      <TableCell align="left">{ticker}</TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="right">
                        {(currentRatio * 100).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        %
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color:
                            currentRatio > targetRatio
                              ? 'blue'
                              : currentRatio < targetRatio
                              ? 'red'
                              : undefined,
                        }}
                      >
                        {(targetRatio * 100).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        %
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2}>
            <LoadingButton
              sx={{
                width: ['-webkit-fill-available', 'inherit'],
                height: ['52px', 'inherit'],
                flex: 1,
              }}
              disabled={
                (sellRecipes?.length ?? 0) <= 0 &&
                (buyRecipes?.length ?? 0) <= 0
              }
              loading={isLoading}
              variant="contained"
              onClick={() => {
                createPositioning(
                  portfolio.id,
                  currency,
                  (sellRecipes?.length ?? 0) > 0
                    ? 'sellPlanning'
                    : 'buyPlanning',
                  modelAssets,
                );
              }}
            >
              시작하기
            </LoadingButton>
          </Stack>
        </>
      );
    }, [
      currency,
      bufferRate,
      isLoading,
      phase,
      modelAssets,
      diffStates,
      realBalance,
    ]);

    const sellPlanning = useMemo(() => {
      if (
        phase !== 'sellPlanning' ||
        !positioning ||
        !sellRecipes ||
        !buyRecipes ||
        !modelAssets
      ) {
        return null;
      }

      return (
        <>
          <Stack
            direction={['column', 'row']}
            p="16px"
            sx={{
              backgroundColor: 'background.grey',
              boxShadow:
                '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
              borderRadius: '8px',
            }}
          >
            <Stack flex={1} direction={['row', 'column']} px="16px">
              <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" flex={1}>
                투자금액
              </Typography>
              <Typography fontWeight="bold">
                {realBalance?.totalAmount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: ['none', 'initial'] }}
            />
            <Stack
              flex={1}
              direction={['row', 'column']}
              px="16px"
              pt={['8px', '0px']}
            >
              <Typography color="rgba(0, 0, 0, 0.6)" flex={1}>
                예상 매도 총액
              </Typography>
              <Typography fontWeight="bold">
                {sum(
                  sellRecipes.map((r) => r.sellShares * r.sellPrice),
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body2">
              원활한 주문을 위해 현재가 대비
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <BufferRateTextField
                initialBufferRate={bufferRate}
                onChange={setBufferRate}
              />

              <Typography variant="body2">
                낮은 가격으로 매도 주문합니다
              </Typography>
              <Box flex={1} />
              <IconButton
                size="small"
                onClick={() => throttledUpdatePosition(portfolio.id)}
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.38);">
              (최소 0.5%부터 최대 5%까지 지정할 수 있습니다)
            </Typography>
          </Stack>

          <TableContainer sx={{ flex: 1, borderRadius: '8px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    '> th': { backgroundColor: '#FAFAFA', fontSize: '12px' },
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    티커
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',

                      minWidth: '100px',
                    }}
                  >
                    회사명
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    현재가
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    매도가
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    매도 수량
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    매도 총액
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sellRecipes?.map(
                  ({
                    ticker,
                    name,
                    currentPrice,
                    sellPrice,
                    sellShares,
                    yesterdayTransactionVolume,
                  }) => {
                    return (
                      <TableRow hover key={ticker}>
                        <TableCell align="left">{ticker}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="right">
                          {currentPrice.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: 'blue',
                          }}
                        >
                          {sellPrice.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell align="right">{sellShares}</TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {yesterdayTransactionVolume !== 0 &&
                            yesterdayTransactionVolume / sellShares < 25 ? (
                              <Tooltip
                                title="체결이 원활하지 않을 수 있습니다!"
                                arrow
                                placement="right"
                              >
                                <WarningIcon fontSize="small" color="warning" />
                              </Tooltip>
                            ) : null}
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 'bold',
                                color: 'blue',
                              }}
                            >
                              {(sellPrice * sellShares).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2}>
            <LoadingButton
              fullWidth
              sx={{
                height: ['52px', 'inherit'],
              }}
              variant="contained"
              loading={isLoading}
              onClick={() => {
                throttledChangePhase(portfolio.id, 'done');
              }}
            >
              리밸런싱 그만두기
            </LoadingButton>
            {(sellRecipes?.length ?? 0) > 0 ? (
              <LoadingButton
                fullWidth
                sx={{
                  height: ['52px', 'inherit'],
                }}
                disabled={(sellRecipes?.length ?? 0) <= 0}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  throttledExecutePositioning(
                    portfolio.id,
                    modelAssets,
                    sellRecipes.map((r) => ({
                      exchange: r.exchange!,
                      currency: r.currency!,
                      orderType: 'sell',
                      ticker: r.ticker,
                      name: r.name,
                      baseShares: r.currentShares,
                      shares: Math.abs(r.sellShares),
                      limitPrice: r.sellPrice,
                    })),
                  );
                }}
              >
                매도하기
              </LoadingButton>
            ) : (
              <LoadingButton
                fullWidth
                sx={{
                  height: ['52px', 'inherit'],
                }}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  if ((buyRecipes?.length ?? 0) > 0) {
                    throttledChangePhase(portfolio.id, 'buyPlanning');
                  } else {
                    throttledChangePhase(portfolio.id, 'done');
                  }
                }}
              >
                {(buyRecipes?.length ?? 0) > 0
                  ? '매수할 주식 보기'
                  : '포트폴리오 완성하기'}
              </LoadingButton>
            )}
          </Stack>
        </>
      );
    }, [phase, positioning, isLoading, sellRecipes, buyRecipes, realBalance]);

    const buyPlanning = useMemo(() => {
      if (
        phase !== 'buyPlanning' ||
        !positioning ||
        !buyRecipes ||
        !modelAssets
      ) {
        return null;
      }

      return (
        <>
          <Stack
            direction={['column', 'row']}
            p="16px"
            sx={{
              backgroundColor: 'background.grey',
              boxShadow:
                '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
              borderRadius: '8px',
            }}
          >
            <Stack flex={1} direction={['row', 'column']} px="16px">
              <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" flex={1}>
                투자금액
              </Typography>
              <Typography fontWeight="bold">
                {realBalance?.totalAmount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: ['none', 'initial'] }}
            />
            <Stack
              flex={1}
              direction={['row', 'column']}
              px="16px"
              pt={['8px', '0px']}
            >
              <Typography color="rgba(0, 0, 0, 0.6)" flex={1}>
                예상 매수 총액
              </Typography>
              <Typography fontWeight="bold">
                {sum(
                  buyRecipes.map((r) => r.buyPrice * r.buyShares),
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {currency}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body2">
              원활한 주문을 위해 현재가 대비
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <BufferRateTextField
                initialBufferRate={bufferRate}
                onChange={setBufferRate}
              />

              <Typography variant="body2">
                높은 가격으로 매수 주문합니다
              </Typography>
              <Box flex={1} />
              <IconButton
                size="small"
                onClick={() => throttledUpdatePosition(portfolio.id)}
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.38);">
              (최소 0.5%부터 최대 5%까지 지정할 수 있습니다)
            </Typography>
          </Stack>

          <TableContainer sx={{ flex: 1, borderRadius: '8px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    '> th': { backgroundColor: '#FAFAFA', fontSize: '12px' },
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    티커
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      minWidth: '100px',
                    }}
                  >
                    회사명
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    현재가
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    매수가
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    매수 수량
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    매수 총액
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buyRecipes.map(
                  ({
                    ticker,
                    name,
                    currentPrice,
                    buyShares,
                    buyPrice,
                    yesterdayTransactionVolume,
                  }) => {
                    return (
                      <TableRow hover key={ticker}>
                        <TableCell align="left">{ticker}</TableCell>
                        <TableCell align="left" sx={{ maxLines: 2 }}>
                          {name}
                        </TableCell>
                        <TableCell align="right">
                          {currentPrice.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: 'red',
                          }}
                        >
                          {buyPrice.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell align="right">{buyShares}</TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {yesterdayTransactionVolume !== 0 &&
                            yesterdayTransactionVolume / buyShares < 25 ? (
                              <Tooltip
                                title="체결이 원활하지 않을 수 있습니다!"
                                arrow
                                placement="right"
                              >
                                <WarningIcon fontSize="small" color="warning" />
                              </Tooltip>
                            ) : null}
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              {(buyPrice * buyShares).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2}>
            <LoadingButton
              fullWidth
              sx={{
                height: ['52px', 'inherit'],
              }}
              variant="contained"
              loading={isLoading}
              onClick={() => {
                throttledChangePhase(portfolio.id, 'done');
              }}
            >
              리밸런싱 그만두기
            </LoadingButton>
            {(buyRecipes?.length ?? 0) > 0 ? (
              <LoadingButton
                fullWidth
                sx={{
                  height: ['52px', 'inherit'],
                }}
                disabled={(buyRecipes?.length ?? 0) <= 0}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  // 현 예수금으로 매수 가능할때 처리
                  throttledExecutePositioning(
                    portfolio.id,
                    modelAssets,
                    buyRecipes.map((r) => ({
                      exchange: r.exchange!,
                      currency: r.currency!,
                      orderType: 'buy',
                      ticker: r.ticker,
                      name: r.name,
                      baseShares: r.currentShares,
                      shares: Math.abs(r.buyShares),
                      limitPrice: r.buyPrice,
                    })),
                  );
                }}
              >
                매수하기
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={{
                  width: ['-webkit-fill-available', 'inherit'],
                  height: ['52px', 'inherit'],
                }}
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  throttledChangePhase(portfolio.id, 'done');
                }}
              >
                포트폴리오 완성하기
              </LoadingButton>
            )}
          </Stack>
        </>
      );
    }, [phase, positioning, modelAssets, buyRecipes, isLoading, realBalance]);

    const trading = useMemo(() => {
      if (!['selling', 'buying'].includes(phase) || !positioning) {
        return null;
      }

      const latestDate = last(positioning.executions)?.executedAt;
      const groupedExecutions = groupBy(positioning.executions, (e) =>
        dayjs(e.executedAt).toISOString(),
      );
      const latestExecutions =
        groupedExecutions?.[dayjs(latestDate).toISOString()];

      const totalCount = sum(latestExecutions?.map((e) => e.shares));
      const fillCount = sum(latestExecutions?.map((e) => e.fillShares));

      const occupiedError = latestExecutions?.some((e) =>
        ['failed', 'canceled', 'rejected'].includes(e.status),
      );
      const cancelableRemainingCount = sum(
        latestExecutions
          ?.filter((e) => e.status === 'open' && e.orderNo && e.shares > 0)
          .map((e) => e.remainingShares),
      );
      const completed =
        sum(
          latestExecutions
            ?.filter(
              (e) => e.status !== 'verified' && e.orderNo && e.shares > 0,
            )
            .map((e) => e.remainingShares),
        ) < 1;

      const CustomLinearProgress = styled(LinearProgress)(() => ({
        height: '6px',
        borderRadius: 5,
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 3,
          background: '#9E7DF9',
        },
      }));

      return (
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              flex={1}
              sx={{
                padding: '24px',
                background: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography variant="headline2">
                {fillCount === totalCount
                  ? '주문  체결 완료'
                  : '주문 중입니다.'}
              </Typography>
              <Typography variant="body2">
                주문이 완료되기 전까지 주문 창을 닫지마세요.
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <CustomLinearProgress
                  sx={{
                    flex: 1,
                    background: 'rgba(0, 0, 0, 0.03)',
                  }}
                  variant="determinate"
                  value={(totalCount > 0 ? fillCount / totalCount : 0) * 100}
                />

                {isSyncing ? (
                  <CircularProgress size="34px" />
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => throttledUpdatePosition(portfolio.id)}
                  >
                    <CircleRefresh />
                  </IconButton>
                )}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle1">
                  {(
                    (totalCount > 0 ? fillCount / totalCount : 0) * 100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  % 진행 {fillCount === totalCount ? '완료' : '중'}
                </Typography>
                <Typography
                  ml="12px"
                  variant="body2"
                  color="rgba(0, 0, 0, 0.6);"
                >
                  {fillCount}/{totalCount}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <TableContainer sx={{ flex: 1, borderRadius: '8px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    '> th': { backgroundColor: '#FAFAFA', fontSize: '12px' },
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    티커
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      minWidth: '100px',
                    }}
                  >
                    회사명
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    거래구분
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    평균 체결가(주문가)
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    체결/주문 수량
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    체결/주문 총액
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    주문상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {latestExecutions?.map((execution) => {
                  return (
                    <TableRow
                      hover
                      key={`${execution.ticker}-${execution.orderNo}`}
                    >
                      <TableCell align="left">{execution.ticker}</TableCell>
                      <TableCell align="left" sx={{ maxLines: 2 }}>
                        {execution.name}
                      </TableCell>
                      <TableCell align="left">
                        {execution.orderType === 'buy' ? '매수' : '매도'}
                      </TableCell>
                      <TableCell align="right">
                        {execution.averageFillPrice.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        (
                        {execution.limitPrice.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        )
                      </TableCell>
                      <TableCell align="right">
                        {execution.fillShares} / {execution.shares}
                      </TableCell>
                      <TableCell align="right">
                        {execution.fillAmount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{' '}
                        /{' '}
                        {(
                          Number(execution.limitPrice) * execution.shares
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {transOrderStatusText(execution.status)}
                          {execution.errorMessage ? (
                            <Tooltip
                              title={execution.errorMessage}
                              arrow
                              placement="right"
                            >
                              <ErrorIcon color="error" />
                            </Tooltip>
                          ) : null}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2}>
            {occupiedError && !completed ? (
              <LoadingButton
                fullWidth
                variant="contained"
                loading={isLoading || isSyncing}
                disabled={cancelableRemainingCount > 0}
                onClick={() => {
                  throttledChangePhase(portfolio.id, 'done');
                }}
              >
                리밸런싱 그만두기
              </LoadingButton>
            ) : null}
            {cancelableRemainingCount > 0 ? (
              <LoadingButton
                fullWidth
                variant="contained"
                loading={isLoading || isSyncing}
                onClick={() => {
                  throttledCancelExecutions(portfolio.id);
                }}
              >
                잔여 수량 취소
              </LoadingButton>
            ) : null}
            {occupiedError ? (
              <LoadingButton
                fullWidth
                variant="contained"
                disabled={cancelableRemainingCount > 0}
                loading={isLoading || isSyncing}
                onClick={() => {
                  throttledChangePhase(
                    portfolio.id,
                    phase === 'buying' ? 'buyPlanning' : 'sellPlanning',
                  );
                }}
              >
                다시 주문하기
              </LoadingButton>
            ) : null}
            <LoadingButton
              fullWidth
              sx={{
                height: ['52px', 'inherit'],
              }}
              variant="contained"
              disabled={!completed}
              loading={isLoading || isSyncing}
              onClick={() => {
                throttledChangePhase(
                  portfolio.id,
                  phase === 'buying' || (buyRecipes?.length ?? 0) <= 0
                    ? 'done'
                    : 'buyPlanning',
                );
              }}
            >
              {phase === 'buying' || (buyRecipes?.length ?? 0) <= 0
                ? '포트폴리오 완성하기'
                : '매수할 주식 보기'}
            </LoadingButton>
          </Stack>
        </>
      );
    }, [phase, isLoading, isSyncing, positioning]);

    const title = useMemo(() => {
      return toTitle(phase);
    }, [phase]);

    return (
      <Stack
        height="100%"
        direction="column"
        p={2}
        ref={ref}
        spacing={2}
        overflow="hidden"
        position="relative"
        {...stackProps}
      >
        <Stack
          direction="row"
          width="100%"
          height="60px"
          flexShrink={0}
          alignItems="center"
          spacing={1}
        >
          {phase === 'simulating' ? (
            <Typography flex={1} fontSize="20px" fontWeight="bold">
              {title}
            </Typography>
          ) : (
            stepProgress
          )}
          <IconButton onClick={() => onClose?.()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {isLoadingPricesError ? (
          <Typography>가격 정보를 불러오는데 오류가 발생하였습니다.</Typography>
        ) : (
          <>
            {simulating}
            {trading}
            {sellPlanning}
            {buyPlanning}
          </>
        )}
        {isLoading ? (
          <Box
            top={0}
            left={0}
            right={0}
            bottom={0}
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(255, 255, 255, 0.75)"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Box
              bgcolor="background.paper"
              borderRadius="8px"
              p="16px"
              border="1px solid #ccc"
              width="fit-content"
              maxWidth={['90vw', '100vw']}
            >
              <LottieAnimation height="100px" animationData={lottieLoading} />

              <Typography
                whiteSpace="pre-line"
                variant="body2"
                textAlign="center"
                color="black"
              >
                {`시장 정보\n불러오는 중...`}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Snackbar
          open={!!notificationMessage}
          autoHideDuration={10000}
          onClose={() => setNotificationMessage(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={() => setNotificationMessage(null)}
            severity="error"
            sx={{ width: '100%', whiteSpace: 'pre-line' }}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
  },
);

PortfolioPositioning.displayName = 'ModelAssetBuyingGuide';
PortfolioPositioning.defaultProps = {
  onClose: undefined,
  onAllocated: undefined,
};

export default PortfolioPositioning;
