import React, {
  useState,
  useContext,
  useMemo,
  ReactElement,
  useCallback,
} from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import {
  Box,
  Drawer,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  List,
  Toolbar,
  Menu,
  MenuItem,
  Avatar,
  CSSObject,
  ListItemButton,
  Stack,
  Button,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { AccountCircle } from '@mui/icons-material';
import { match, useHistory, useLocation, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import { isFunction, isString } from 'lodash';
import { isIE } from 'react-device-detect';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useAmplitude } from 'react-amplitude-hooks';
import MenuIcon from '@mui/icons-material/Menu';

import dayjs from '~/utils/dayjs';
import { AppContext } from '~/AppContext';
import StyledButton from '~/components/StyledButton';
import logo from '~/assets/logo.svg';
import { ReactComponent as ScreenerIcon } from '~/assets/icon/icon_screener.svg';
import { ReactComponent as BacktestIcon } from '~/assets/icon/icon_backtest.svg';
import { ReactComponent as DocumentIcon } from '~/assets/icon/icon_document.svg';
import { ReactComponent as MyStrategyIcon } from '~/assets/icon/icon_my_strategy.svg';
import { ReactComponent as PortfolioIcon } from '~/assets/icon/icon_portfolio.svg';
import { ReactComponent as StrategyIcon } from '~/assets/icon/icon_strategy.svg';

import { ReactComponent as ScreenerIconOn } from '~/assets/icon/icon_screener_on.svg';
import { ReactComponent as BacktestIconOn } from '~/assets/icon/icon_backtest_on.svg';
import { ReactComponent as DocumentIconOn } from '~/assets/icon/icon_document_on.svg';
import { ReactComponent as CafeIconOn } from '~/assets/icon/icon_cafe_on.svg';
import { ReactComponent as MyStrategyIconOn } from '~/assets/icon/icon_my_strategy_on.svg';
import { ReactComponent as PortfolioIconOn } from '~/assets/icon/icon_portfolio_on.svg';
import { ReactComponent as StrategyIconOn } from '~/assets/icon/icon_strategy_on.svg';

import inquiryImage from '~/assets/image/inquiry.png';
import { ReactComponent as NewIcon } from '~/assets/icon/icon_new.svg';
import { ReactComponent as InfoIcon } from '~/assets/icon/icon_info_off.svg';
import api from '~/api';
import { isNotSupportedBrowser } from '~/utils/useragent';
import VoucherRegisterModal from '~/containers/screener/VoucherRegisterModal';

const headerHeight = 60;
const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
//   open?: boolean;
// }>(({ theme, open }) => ({
//   flexGrow: 1,
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,w
//     }),
//     marginLeft: 0,
//   }),
// }));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.modal + 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  flexBasis: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

const voucherGuideLink =
  'https://doomoolmori.notion.site/a75e8c144e3b4332bda180f3ee5e7e95';

function Layout({ children }: Props) {
  const { logEvent } = useAmplitude();
  const context = useContext(AppContext);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isOpenRegisterVoucher, setIsOpenRegisterVoucher] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isShowKakaoAlertOverlay, setIsShowKakaoAlertOverlay] = useState(true);

  const registerVoucherCode = (code: string) => {
    api.screener
      .registerVoucher(code)
      .then(async () => {
        setIsOpenRegisterVoucher(false);
        return true;
      })
      .catch((e: any) => {
        const errorMessageMap: { [k: string]: string } = {
          NOT_FOUND: `존재하지 않는 이용권 코드입니다.\n등록하신 코드를 재확인해주세요.`,
          NOT_ISSUED_VOUCHER:
            '아직 사용할 수 없는 이용권 코드입니다.\n사용 가능한 기간을 확인해주세요.',
          EXPIRED_VOUCHER: `등록할 수 있는 기간이 만료된 이용권 코드입니다.\n새로운 이용권을 구매해주세요. `,
          USED_VOUCHER: `이미 사용된 이용권 코드입니다.\n새로운 이용권을 구매해주세요. `,
          default: '코드 등록에 실패했습니다.',
        };
        context?.state?.showToast?.(
          'error',
          errorMessageMap?.[e?.response?.data?.errorCode ?? 'default'] ??
            errorMessageMap.default,
          {
            position: 'bottom-left',
          },
        );
        return false;
      })
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          const usageRes = await api.screener.usage();
          if (usageRes?.data?.planeName) {
            context?.state?.showToast?.(
              'success',
              `${usageRes.data.planeName} 이용권이 등록되었습니다.`,
              {
                position: 'bottom-left',
              },
            );
          }
          context?.dispatch?.({
            type: 'SET_USAGE',
            screenerUsage: usageRes.data,
          });
        }
      })
      .catch((e) => {
        console.error('fail', e);
      });
  };

  type NavigationButtonProps = {
    onIcon?: ReactElement;
    offIcon?: ReactElement;
    endIcon?: ReactElement;
    title: string;
    to: string | (() => void);
    match?: match | null;
  };

  const NavigationButton = useCallback(
    ({ onIcon, offIcon, title, to, match, endIcon }: NavigationButtonProps) => {
      const isHighlighted =
        match ??
        (isString(to)
          ? matchPath(location.pathname, {
              path: to,
              strict: true,
              sensitive: true,
            })
          : null);

      return (
        <ListItemButton
          sx={{
            borderRadius: '16px',
            backgroundColor: isHighlighted ? '#F6EDFF' : null,
          }}
          onClick={() => {
            handleDrawerClose();
            logEvent?.('menu clicked', { 'page url': to });

            if (isFunction(to)) {
              to?.();
            } else {
              history.push(to);
            }
          }}
        >
          <ListItemIcon
            sx={{
              // opacity: isHilighted ? 1 : 0.38,
              minWidth: '40px',
            }}
          >
            {isHighlighted ? onIcon : offIcon}
          </ListItemIcon>
          <Stack direction="row" alignItems="center" spacing="4px">
            <ListItemText
              sx={{
                color: isHighlighted ? null : '#757575',
              }}
              primary={title}
            />
            {endIcon}
          </Stack>
        </ListItemButton>
      );
    },
    [location, logEvent, history],
  );

  const kakaoAlertOverlay = useMemo(() => {
    return (
      <Box
        display={
          isNotSupportedBrowser() && isShowKakaoAlertOverlay ? 'flex' : 'none'
        }
        zIndex={2000}
        position="absolute"
        width="100%"
        height="100%"
        top="0px"
        bottom="0px"
        left="0px"
        right="0px"
        bgcolor="rgba(0, 0, 0, 0.3)"
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          bgcolor="background.paper"
          px="24px"
          py="32px"
          alignItems="center"
          display="flex"
          borderRadius="8px"
        >
          <Typography whiteSpace="pre-line" align="center">
            {`테일러는 ${
              navigator.userAgent.match('KAKAOTALK') ? '카카오' : '링크드인'
            } 브라우저를 지원하지 않습니다.\n다른 브라우저 열기 기능을 사용해주세요.`}
          </Typography>
          {/* <Button
            sx={{ marginTop: '16px', width: 'fit-content' }}
            variant="contained"
            onClick={() => {
              setIsShowKakaoAlertOverlay(false);
            }}
          >
            확인
          </Button> */}
        </Stack>
      </Box>
    );
  }, [isShowKakaoAlertOverlay]);

  const onSignOutClick = () => {
    setAnchorEl(null);
    api.auth.signOut().finally(() => {
      context?.dispatch?.({
        type: 'SIGN_OUT',
      });
      // history.replace('/');
      if (process.env.REACT_APP_ENV === 'production') {
        window.open(`https://tailor.im`, '_self');
      } else {
        const signInUrl = `${
          process.env.REACT_APP_API_URL
        }/auth/google?${qs.stringify({
          path: location.pathname,
          query: location.search,
          referrer:
            (document.referrer?.length ?? 0) > 0
              ? document.referrer
              : 'unknown',
        })}`;
        window.open(signInUrl, '_self');
      }
    });
  };

  const profileContainer = useMemo(() => {
    if (!context?.state) {
      return null;
    }
    if (!context?.state?.accessToken && !context?.state?.user) {
      // 주소 변수로 따로 뺴야한다
      return (
        <StyledButton
          variant="contained"
          onClick={() => {
            const signInUrl = `${
              process.env.REACT_APP_API_URL
            }/auth/google?${qs.stringify({
              referrer:
                (document.referrer?.length ?? 0) > 0
                  ? document.referrer
                  : 'unknown',
            })}`;
            window.open(signInUrl, '_self');
          }}
        >
          로그인
        </StyledButton>
      );
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {context?.state?.user ? (
            context?.state?.user?.imageUrl ? (
              <Avatar
                alt={context?.state.user.name}
                src={context?.state.user.imageUrl}
              />
            ) : (
              <AccountCircle />
            )
          ) : null}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 3,
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={onSignOutClick}>로그아웃</MenuItem>
        </Menu>
      </Box>
    );
  }, [context?.state?.accessToken, context?.state?.user, anchorEl]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuContents = useMemo(() => {
    if (!context?.state?.user) {
      return null;
    }

    return (
      <>
        <List
          sx={{
            px: '16px',
          }}
        >
          <NavigationButton
            onIcon={<StrategyIconOn />}
            offIcon={<StrategyIcon />}
            title="다이렉트 인덱싱"
            to="/select-strategy"
          />
          <NavigationButton
            onIcon={<PortfolioIconOn />}
            offIcon={<PortfolioIcon />}
            title="내 포트폴리오"
            to="/portfolio"
          />
          <NavigationButton
            onIcon={<MyStrategyIconOn />}
            offIcon={<MyStrategyIcon />}
            title="내 전략"
            to="/strategy"
          />
          <Divider sx={{ my: '16px' }} flexItem />

          <NavigationButton
            onIcon={<ScreenerIconOn />}
            offIcon={<ScreenerIcon />}
            title="종목찾기"
            to="/screener"
            match={matchPath(location.pathname, {
              path: ['/screener', '/screener-result'],
              strict: true,
              sensitive: true,
            })}
          />
          <NavigationButton
            onIcon={<BacktestIconOn />}
            offIcon={<BacktestIcon />}
            title="백테스팅"
            to="/backtest"
            match={matchPath(location.pathname, {
              path: ['/backtest', '/backtest-result'],
              strict: true,
              sensitive: true,
            })}
          />

          <Divider sx={{ my: '16px' }} flexItem />
          <NavigationButton
            onIcon={<DocumentIconOn />}
            offIcon={<DocumentIcon />}
            title="테일러에 의견보내기"
            to={() => window.open('https://forms.gle/1yQtRTES5VGExSgXA', '_')}
          />
          <NavigationButton
            onIcon={<CafeIconOn />}
            offIcon={<CafeIconOn />}
            title="네이버 카페 바로가기"
            to={() =>
              window.open('https://cafe.naver.com/directindexingtailor', '_')
            }
          />
          <NavigationButton
            onIcon={<CafeIconOn />}
            offIcon={<InfoIcon />}
            endIcon={<NewIcon style={{ marginTop: '2px' }} />}
            title="데이터 정책 변경 안내"
            to={() =>
              window.open(
                'https://doomoolmori.notion.site/a0b74ee34fe242e0817d1bd8fe8636b8',
                '_',
              )
            }
          />
        </List>
        <Stack flex={1} justifyContent="end" px="16px" pb="24px">
          <Stack display={['inherit', 'none']}>
            <Divider sx={{ my: '16px' }} flexItem />
            <Stack direction="row" mx="16px">
              {context?.state?.user ? (
                context?.state?.user?.imageUrl ? (
                  <Avatar
                    sx={{ width: '24px', height: '24px' }}
                    alt={context?.state.user.name}
                    src={context?.state.user.imageUrl}
                  />
                ) : (
                  <AccountCircle />
                )
              ) : null}
              <Stack ml="16px" spacing="16px">
                <Typography variant="caption">
                  {context?.state?.user?.email}
                </Typography>
                <Stack direction="row" spacing="8px">
                  <Button
                    variant="contained"
                    sx={{
                      width: 'fit-content',
                      height: '32px',
                      backgroundColor: '#60CF8C',
                    }}
                    onClick={() => setIsOpenRegisterVoucher(true)}
                  >
                    코드등록
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onSignOutClick}
                    sx={{
                      width: 'fit-content',
                      height: '32px',
                      borderColor: '#D9DCE0',
                    }}
                  >
                    로그아웃
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {context?.state.screenerUsage ? (
            <Stack my="10px" p="16px" bgcolor="background.grey" spacing="4px">
              {context.state.screenerUsage.planeName !== 'FREE' ? (
                <Typography variant="body2" color="text.secondary">
                  <span
                    style={{
                      padding: '4px',
                      color: '#FC916F',
                      backgroundColor: '#FFEBE4',
                      borderRadius: '4px',
                    }}
                  >
                    {context?.state?.screenerUsage.planeName}
                  </span>
                  {context.state.screenerUsage.planeName !== 'FREE'
                    ? ` ~${dayjs(context?.state.screenerUsage?.endAt).format(
                        'YYYY.MM.DD',
                      )} 까지 이용`
                    : ''}
                </Typography>
              ) : null}
              <Typography variant="body2" color="text.secondary">
                종목찾기 1일{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {(
                    context?.state?.screenerUsage?.policy?.screening?.limit ?? 0
                  ).toLocaleString()}
                  회
                </span>
                , 백테스팅 1일{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {(
                    context?.state?.screenerUsage?.policy?.backtesting?.limit ??
                    0
                  ).toLocaleString()}
                </span>
                회
              </Typography>
              {context.state.screenerUsage.planeName === 'FREE' ? (
                <Stack
                  mt="12px"
                  bgcolor="#E5E7EB"
                  py="8px"
                  px="10px"
                  borderRadius="4px"
                  onClick={() => {
                    window.open(voucherGuideLink, '_blank');
                  }}
                >
                  <Typography color="#677380" variant="body2">
                    백테스팅 1일 50회?!
                  </Typography>
                  <Typography color="#677380" variant="body2" fontWeight="bold">
                    이용권 구매하기
                  </Typography>
                </Stack>
              ) : dayjs(context?.state.screenerUsage?.endAt).diff(
                  dayjs(),
                  'day',
                ) <= 5 ? (
                <Stack
                  mt="12px"
                  bgcolor="#E5E7EB"
                  py="8px"
                  px="10px"
                  borderRadius="4px"
                  onClick={() => {
                    window.open(voucherGuideLink, '_blank');
                  }}
                >
                  <Typography color="#677380" variant="body2">
                    D-
                    {dayjs(context?.state.screenerUsage?.endAt).diff(
                      dayjs(),
                      'day',
                    )}
                    ! 재구매시 10% 할인!
                  </Typography>
                  <Typography color="#677380" variant="body2" fontWeight="bold">
                    이용권 구매하기
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          <Stack
            sx={{ backgroundColor: (theme) => theme.palette.background.grey }}
            position="relative"
            borderRadius="4px"
            height="124px"
            py="16px"
            pl="16px"
          >
            <img
              style={{ position: 'absolute', right: 0, bottom: 0 }}
              width="99px"
              height="88px"
              src={inquiryImage}
              alt="inquiry"
            />
            <Typography flex={1} variant="subtitle1">
              테일러 처음 써봐요.
              <br />
              어떻게 쓰면 될까요?
            </Typography>
            <StyledButton
              variant="contained"
              sx={{
                width: 'fit-content',
                borderRadius: '50px',
              }}
              onClick={() => {
                window.open(
                  'https://doomoolmori.notion.site/89f54b7b7e634300bb9fb9dab03088e1',
                  '_blank',
                );
              }}
            >
              첫사용 가이드 보기
            </StyledButton>
          </Stack>
        </Stack>
      </>
    );
  }, [theme, history, open, context?.state?.user, location?.pathname]);

  const browserAlertBanner = useMemo(() => {
    return (
      <Box
        display={isIE ? 'flex' : 'none'}
        height="40px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        bgcolor="#FF5656"
      >
        <Stack direction="row">
          <ReportProblemIcon htmlColor="#E2FF32" />
          <Typography pl="8px">
            테일러 베타 버전은 크롬 웹브라우저에 최적화 되어있습니다.
          </Typography>
        </Stack>
      </Box>
    );
  }, []);
  return (
    <Box
      sx={{ display: 'flex', width: '100%', height: '-webkit-fill-available' }}
    >
      <Helmet>
        <meta content={theme.palette.primary.main} name="theme-color" />
        <meta
          content={theme.palette.primary.main}
          name="msapplication-navbutton-color"
        />
        <meta
          content={theme.palette.primary.main}
          name="apple-mobile-web-app-status-bar-style"
        />
        <meta content="yes" name="apple-mobile-web-app-capable" />
      </Helmet>
      <CssBaseline />
      <AppBar open={false}>
        {browserAlertBanner}
        <Toolbar>
          {/*
          {context?.state?.user ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          */}
          <Box flex={1}>
            <Box component="img" src={logo} onClick={() => history.push(`/`)} />
          </Box>
          <Stack display={['none', 'flex']} direction="row" alignItems="center">
            <StyledButton
              variant="contained"
              sx={{ backgroundColor: '#60CF8C', height: '42px' }}
              onClick={() => setIsOpenRegisterVoucher(true)}
            >
              코드등록
            </StyledButton>
            {profileContainer}
          </Stack>
          <Box display={['flex', 'none']}>
            <Box />
            <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={open}
        onClose={handleDrawerClose}
        anchor="right"
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 3,
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {menuContents}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <DrawerHeader />
        <Divider />

        {menuContents}
      </Drawer>

      <Stack
        component="main"
        pt={`${headerHeight}px`}
        pl={[0, `${drawerWidth}px`]}
        width="100%"
        flex={1}
        overflow="hidden"
      >
        {children}
      </Stack>
      <VoucherRegisterModal
        open={isOpenRegisterVoucher}
        onClose={() => setIsOpenRegisterVoucher(false)}
        onConfirm={registerVoucherCode}
        errorMessage={
          context?.state?.screenerUsage &&
          context?.state?.screenerUsage?.planeName !== 'FREE'
            ? '유료 이용권 사용 기간 중에는 추가로 등록할 수 없습니다.'
            : null
        }
      />
      {kakaoAlertOverlay}
    </Box>
  );
}
Layout.defaultProps = {
  children: null,
};

export default Layout;
