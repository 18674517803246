import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Center, HStack, Flex } from '@chakra-ui/layout';
import { ChakraProvider, Image, VStack } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box, Typography, Tooltip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import dayjs from '~/utils/dayjs';
import api from '~/api';
import {
  QueryFactor,
  YieldBenchmarkSnapshot,
  YieldIndex,
} from '~/api/backtesting';
import { PortfolioBacktestingResult } from '~/api/portfolio';
import { Body1, Body2, Caption, H1, H2 } from '~/components/Typography';
import useFetch from '~/hooks/useFetch';
import { getThemeData } from '~/utils/theme';
import BackTestingResultGrid from './BackTestingResultGrid';
import { PostBacktestingBody, SortData } from '~/api/screener';
import StyledButton from '~/components/StyledButton';
import notFound from '~/assets/image/not_found.svg';
import iconInfo from '~/assets/icon/icon_info.svg';

export type ChartProps = {
  value: YieldIndex;
  nationCode: number;
};

type Props = {
  request: PostBacktestingBody;
  result: PortfolioBacktestingResult;
  nationCode: number;
  orders: Array<SortData>;

  conditions: Array<QueryFactor>;
};
/* eslint-disable no-nested-ternary */
function BackTestResultContainer({
  request,
  result,
  nationCode,
  orders,
  conditions,
}: Props) {
  const themeData = getThemeData();
  const history = useHistory();
  const [t] = useTranslation();

  if (
    !result?.yieldIndexResult?.bmStats ||
    !result?.yieldIndexResult?.portBMYieldIndexes ||
    (result?.yieldIndexResult?.portBMYieldIndexes?.length ?? 0) === 0 ||
    !result?.yieldIndexResult?.portStats
  ) {
    return (
      <VStack
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        spacing="16px"
        backgroundColor="white"
        padding={['12px 16px', '24px 32px']}
        border={`solid 1px ${themeData.colors.gray[200]}`}
      >
        <Image src={notFound} />
        <VStack spacing="4px">
          <Typography variant="headline2">
            백테스팅 결과를 불러오지 못했습니다.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            뒤로가기 버튼을 눌러, 다시 백테스팅을 시도해주세요.
          </Typography>
        </VStack>
        <StyledButton
          variant="outlined"
          onClick={() => {
            history.goBack();
          }}
        >
          뒤로가기
        </StyledButton>
      </VStack>
    );
  }

  const downloadMonthlyReport = useCallback(() => {
    if (!request) {
      return;
    }
    api.screener
      .downloadBacktestingMonthlyReportXlsx(request)
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${[
          'Tailor_Backtesting_Portfolio',
          dayjs().format('YYYYMMDD_HHmmss'),
        ].join('_')}.xlsx`;
        anchor.click(); // anchor를 다운로드 링크로 만들고 강제로 클릭 이벤트 발생
        window.URL.revokeObjectURL(url); // 메모리에서 해제
      })
      .catch((e) => console.error('fail', e));
  }, [request]);

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'scroll',
        background: 'white',
        padding: ['12px 16px', '24px 32px'],
        border: `solid 1px ${themeData.colors.gray[200]}`,
      }}
    >
      <Box width="100%" height="100%" paddingBottom="12px">
        <HStack>
          <H2 bold color={themeData.colors.text1}>
            {t('text.performanceAnalysis')}
          </H2>
          <Body1 color={themeData.colors.text2}>
            {result.yieldIndexResult.portBMYieldIndexes[0].startDate} ~{' '}
            {
              result.yieldIndexResult.portBMYieldIndexes[
                result.yieldIndexResult.portBMYieldIndexes.length - 1
              ].endDate
            }
          </Body1>
        </HStack>
        <Box
          component="span"
          mt="4px"
          alignItems="center"
          whiteSpace="pre-wrap"
        >
          <Typography component="span" variant="body2" color="text.secondary">
            본 백테스팅 결과에는 생존 편향
          </Typography>
          <Tooltip
            title="생존 편향(Survivorship Bias)이란, 상장폐지된 기업들은 고려하지 않은 채 생존한 기업들만 고려하여 과거 수익률이 높아 보이는 오류입니다."
            arrow
            placement="bottom"
            enterTouchDelay={0}
          >
            <ErrorOutline
              sx={{
                marginLeft: '4px',
                width: '14px',
                height: '14px',
                color: 'text.secondary',
              }}
            />
          </Tooltip>
          <Typography component="span" variant="body2" color="text.secondary">
            , 미래 참조 편향
          </Typography>
          <Tooltip
            title="미래 참조 편향(Look-ahead Bias)이란, 정보가 공개되는 시점을 고려하지 않아 기업들의 정보를 미리 확인하는 것처럼 처리되어 과거 수익률이 높아 보이는 오류입니다."
            arrow
            placement="bottom"
            enterTouchDelay={0}
          >
            <ErrorOutline
              sx={{
                marginLeft: '4px',
                marginRight: '4px',
                width: '14px',
                height: '14px',
                color: 'text.secondary',
              }}
            />
          </Tooltip>
          <Typography component="span" variant="body2" color="text.secondary">
            이 없습니다.
          </Typography>
        </Box>

        <BackTestingResultGrid
          nationCode={nationCode}
          result={result.yieldIndexResult}
          onDownloadMonthlyReportXlsx={() => downloadMonthlyReport()}
        />
      </Box>
    </Box>
  );
}

export default BackTestResultContainer;
