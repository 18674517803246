import { ReactElement, useContext, useEffect, useReducer } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Grid, Box } from '@mui/material';

import ScreenerOptionContainer from '~/containers/screener/ScreenerOptionContainer';
import ConditionsStorageContainer from '~/containers/conditions/ConditionsStorageContainer';
import FactorListContainer from '~/containers/factor/FactorList';
import FactorHistogramContainer from '~/containers/factor/FactorHistogramContainer';
import IndexedTab from '~/components/IndexedTab';
import ScreenerExecButton from '~/containers/screener/ScreenerExecButton';
import { getThemeData } from '~/utils/theme';
import { AppContext } from '~/AppContext';
import ScreenerHeader from '~/containers/screener/ScreenerHeader';
import StyledButton from '~/components/StyledButton';

type Props = {
  children: ReactElement;
};

function ScreenerView() {
  function Monitoring() {
    const location = useLocation();
    const [t] = useTranslation();
    return (
      <>
        <Grid
          container
          display={['none', 'flex']}
          width="100%"
          height="100%"
          columns={8}
        >
          <Grid item sm={2} overflow="scroll" height="100%">
            <ScreenerOptionContainer />
            <Box
              sx={{
                position: 'absolute',
                bottom: '36px',
                width: 'fit-content',
                zIndex: 2,
              }}
            >
              <ConditionsStorageContainer
                isFullScreen={false}
                onUpdate={() => {
                  // console.log('');
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} overflow="hidden" height="100%">
            <Grid
              container
              width="100%"
              height="100%"
              overflow="hidden"
              columns={6}
              // templateColumns="repeat(6, 1fr)"
            >
              <Grid
                item
                xs={2}
                sm={2}
                overflow="scroll"
                bgcolor="white"
                height="100%"
              >
                <FactorListContainer />
              </Grid>

              <Grid
                item
                xs={4}
                sm={4}
                overflow="scroll"
                bgcolor="white"
                height="100%"
              >
                <FactorHistogramContainer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display={['inherit', 'none']} width="100%">
          <IndexedTab
            lastActionButton={
              <>
                <StyledButton
                  sx={{
                    flex: 1,
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(window.location.href)
                      .finally(() => {
                        alert('URL 링크가 복사되었습니다.');
                      });
                  }}
                >
                  전략 공유
                </StyledButton>
                <ScreenerExecButton sx={{ flex: 1 }} />
              </>
            }
            hasStorageButton
            items={[
              {
                title: `1. ${t('text.defaultSettings')}`,
                child: <ScreenerOptionContainer />,
              },
              {
                title: `2. ${t('text.factorSettings')}`,
                child: <FactorListContainer />,
              },
              {
                title: `3. ${t('text.factorDetailSettings')}`,
                child: <FactorHistogramContainer />,
              },
            ]}
          />
        </Box>
      </>
    );
  }
  const themeData = getThemeData();
  const location = useLocation<{ error: boolean }>();
  const history = useHistory();
  const { state } = useContext(AppContext) ?? {};

  useEffect(() => {
    if (location?.state?.error) {
      state?.showToast('error', location.state.error, {
        position: 'bottom-left',
      });
    }
    history.replace(location, { ...location.state, error: undefined });
  }, [location?.state?.error]);

  return (
    <Flex
      h={['-webkit-fill-available', '100%']}
      w="100%"
      flexDirection="column"
    >
      <ScreenerHeader />

      <Flex
        h={['calc(100vh - 150px)', 'calc(100vh - 156px)']}
        bg={themeData.colors.background}
      >
        <Monitoring />
      </Flex>
    </Flex>
  );
}

export default ScreenerView;
