import { forwardRef, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

import { Factor, FactorCategory, FactorControlSpec } from '~/api/strategy';
import SelectableStackItem from './SelectableStackItem';
import StyledButton from '~/components/StyledButton';
import FactorControl from '~/components/factor/FactorControl';
import { FactorControlValue } from '~/components/factor/types';
import FactorHistogramItem from '~/components/factor/FactorHistogramItem';

type FactorCustomizerProps = {
  factorCategories: FactorCategory[];
  factors: Factor[];
  specMap: { [id: Factor['id']]: FactorControlSpec };
  conditionMap: {
    [id: Factor['id']]: [FactorControlValue, FactorControlValue];
  };
  onRemoveAllFactors: (value: boolean) => void;
  onAddFactor?: (factorId: Factor['id']) => void;
  onRemoveFactor?: (factorId: Factor['id']) => void;
  onChangeCondition?: (
    factorId: Factor['id'],
    value: [FactorControlValue, FactorControlValue],
  ) => void;
} & StackProps;

function FactorGroup({
  category,
  factors,
  appliedFactorIds,
  onChangeFactor,
  ...stackProps
}: {
  category: FactorCategory;
  factors: Factor[];
  appliedFactorIds?: number[];
  onChangeFactor?: (factorId: number, checked: boolean) => void;
} & StackProps) {
  const [isExpended, setIsExpended] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { logEvent } = useAmplitude();

  const validFactors = useMemo(() => {
    return factors.filter((f) => i18n.exists(`factor.${f.id}.name`));
  }, [factors]);

  return (
    <Stack {...stackProps}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        onClick={() => setIsExpended((state) => !state)}
      >
        <IconButton size="small">
          {isExpended ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Typography flex={1} whiteSpace="pre" variant="body1">
          {t(`factorCategory.${category.name}.name`)}
        </Typography>
        <Typography
          sx={{
            background: 'rgba(236, 233, 241, 0.4)',
            borderRadius: '4px',
          }}
          py="2px"
          px="8px"
          variant="subtitle2"
          color="#9E7DF9"
        >
          {validFactors.length}
        </Typography>
      </Stack>
      <Stack
        display={isExpended ? 'flex' : 'none'}
        mt="8px"
        direction="column"
        spacing="16px"
      >
        {validFactors.map((factor) => {
          const selected =
            appliedFactorIds?.some((v) => v === factor.id) ?? false;
          return (
            <SelectableStackItem
              key={`factor-${factor.id}`}
              selected={selected}
              direction="column"
              alignItems="start"
              bgcolor="white"
              // sx={{
              //   backgroundColor: (theme) =>
              //     selected ? theme.palette.grey['100'] : 'transparent',
              // }}
              p="16px"
            >
              <Stack direction="row" alignItems="center">
                <Checkbox
                  sx={{
                    padding: '0px',
                  }}
                  color="secondary"
                  checked={selected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      logEvent('factor list clicked', {
                        'factor title': factor.name,
                        'factor category': factor.categoryName,
                      });
                    }
                    onChangeFactor?.(factor.id, e.target.checked);
                  }}
                />
                <Typography paddingLeft="4px" variant="headline2">
                  {t(`factor.${factor.id}.name`)}
                </Typography>
              </Stack>

              {factor.id < 100 ? (
                <>
                  <Divider flexItem sx={{ my: '12px' }} />

                  <Typography
                    variant="body2"
                    whiteSpace="pre-line"
                    color="text.secondary"
                  >
                    {t(`factor.${factor.id}.conditionDescription`, '')}
                  </Typography>
                  <Divider flexItem sx={{ my: '12px' }} />
                </>
              ) : (
                <div />
              )}
              <Typography variant="body1" color="text.secondary">
                {t(`factor.${factor.id}.description`)}
              </Typography>
            </SelectableStackItem>
          );
        })}
      </Stack>
    </Stack>
  );
}
FactorGroup.defaultProps = {
  appliedFactorIds: [],
  onChangeFactor: null,
};

const FactorCustomizer = forwardRef(
  (
    {
      factorCategories,
      factors,
      specMap,
      conditionMap,
      onAddFactor,
      onRemoveFactor,
      onRemoveAllFactors,
      onChangeCondition,
      ...stackProps
    }: FactorCustomizerProps,
    ref,
  ) => {
    // 왼쪽 팩터리스트
    // 오른쪽 고른 항목 리스트
    // 변경사항 상위로 전달
    const [t] = useTranslation();
    const appliedFactorIds = useMemo(() => {
      return Object.keys(conditionMap).map((k) => Number(k));
    }, [conditionMap]);

    const groupList = useMemo(() => {
      return factorCategories.map((category) => {
        return {
          category,
          factors: factors.filter(
            (factor) => factor.categoryId === category.id,
          ),
        };
      });
    }, [factorCategories, factors]);

    return (
      <Stack {...stackProps} ref={ref} direction="column">
        <Divider />
        <Stack direction="row" width="100%" height="100%" overflow="hidden">
          <Stack direction="column" overflow="auto" flex={1} marginRight="16px">
            <Stack px={2} pt={1} direction="row" alignItems="center">
              <Typography flex={1} variant="headline2">
                조건 선택
              </Typography>
              <StyledButton
                startIcon={<RefreshIcon />}
                onClick={() => {
                  appliedFactorIds.forEach((id) => onRemoveFactor?.(id));
                }}
              >
                초기화
              </StyledButton>
            </Stack>
            <Stack direction="row" alignItems="center" spacing="6px" px="4px">
              <Checkbox
                size="small"
                color="secondary"
                sx={{
                  width: '20px',
                }}
                checked={Object.keys(conditionMap).length < 1}
                onChange={(e) => {
                  if (e.target.checked) {
                    appliedFactorIds.forEach((id) => onRemoveFactor?.(id));
                  }
                  onRemoveAllFactors(e.target.checked);
                  // true:
                  // 모두 삭제
                  // false
                  // checked풀기
                }}
              />
              <Typography variant="body2" fontWeight="bold">
                조건 선택 안함
              </Typography>
            </Stack>

            {groupList.map((group) => {
              return (
                <FactorGroup
                  key={`group-${group.category.id}`}
                  py={1}
                  category={group.category}
                  factors={group.factors}
                  appliedFactorIds={appliedFactorIds}
                  onChangeFactor={(factorId: number, checked) => {
                    (checked ? onAddFactor : onRemoveFactor)?.(factorId);
                  }}
                />
              );
            })}
          </Stack>
          <Stack direction="row" display={['none', 'flex']} flex={1}>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" flex={1} height="100%" overflow="auto">
              <Stack
                height="45px"
                px={2}
                pt={1}
                direction="row"
                alignItems="center"
              >
                <Typography flex={1} variant="headline2">
                  상세 설정
                </Typography>
              </Stack>
              {appliedFactorIds.flatMap((id) => {
                const factor = factors.find((factor) => factor.id === id);
                if (!factor) {
                  return null;
                }

                // factor,
                // onUpdateRange,
                // factorHistogram,
                // value,

                return [
                  <FactorHistogramItem
                    key={`factor-${factor.id}`}
                    factor={factor}
                    factorHistogram={specMap[factor.id]}
                    value={conditionMap[factor.id]}
                    onUpdateRange={(
                      range: [FactorControlValue, FactorControlValue],
                    ) => {
                      onChangeCondition?.(factor.id, range);
                    }}
                    onDelete={(factor) => {
                      onRemoveFactor?.(factor.id);
                    }}
                  />,

                  <Divider key={`divider-${factor.id}`} sx={{ mt: 2 }} />,
                ];
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

FactorCustomizer.displayName = 'FactorCustomizer';
FactorCustomizer.defaultProps = {
  onAddFactor: undefined,
  onRemoveFactor: undefined,
  onChangeCondition: undefined,
};

export default FactorCustomizer;
