import React, { useEffect, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Switch,
  TableFooter,
  IconButton,
  TableContainerProps,
  Box,
  Typography,
  Theme,
  Stack,
} from '@mui/material';
import { isNil, orderBy, some, sum, take } from 'lodash';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router';

import { Company } from '~/api/strategy';
import {
  BacktestingComapnyRebalancingInfo,
  ExcludedCompany,
} from '~/api/backtesting';

interface Column {
  id: keyof Company;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

interface CompanyListProps {
  companies: Company[];
  rebalancingList: BacktestingComapnyRebalancingInfo[];
  count?: number;
  matchedCount?: number;
  excludedCompanies?: ExcludedCompany[];
  isCompact?: boolean;
  onChangeExcluding?: (company: ExcludedCompany, excluded: boolean) => void;
}

CompanyList.defaultProps = {
  count: undefined,
  matchedCount: undefined,
  excludedCompanies: null,
  isCompact: false,
  onChangeExcluding: null,
};

function CompanyList({
  companies,
  rebalancingList,
  count,
  matchedCount,
  excludedCompanies,
  isCompact,
  onChangeExcluding,
  ...tableContainerProps
}: CompanyListProps & TableContainerProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    company: Company,
  ) => {
    onChangeExcluding?.(
      {
        id: company.cosmosCode,
        ticker: company.ticker,
        name:
          company.companyLocalName && company.companyLocalName.length > 0
            ? company.companyLocalName
            : company.companyName,
      },
      !e.target.checked,
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredCompanies = useMemo(() => {
    if (isNil(count) || (excludedCompanies?.length ?? 0) < 1) {
      return companies;
    }
    const excludedList = companies.filter((c, i) => {
      return i < count && some(excludedCompanies, (e) => e.id === c.cosmosCode);
    });

    return take(companies, count + excludedList.length);
  }, [companies, count, excludedCompanies]);

  const totalValue = useMemo(() => {
    return filteredCompanies.reduce((r, company) => {
      if (!some(excludedCompanies, (c) => c.id === company.cosmosCode)) {
        const rebalancingInfo = rebalancingList.find(
          (r) => r.cosmosCode === company.cosmosCode,
        );
        return r + (rebalancingInfo?.weight ?? 0);
      }
      return r;
    }, 0);
  }, [filteredCompanies, excludedCompanies]);

  useEffect(() => {
    if (rowsPerPage * page > filteredCompanies.length) {
      setPage(0);
    }
  }, [page, rowsPerPage, filteredCompanies?.length]);

  const tableHeader = useMemo(() => {
    return (
      <TableRow>
        {isCompact ? (
          <TableCell
            sx={{
              borderBottomColor: 'transparent',
              backgroundColor: '#FAFAFA',
            }}
          >
            티커/종목명
          </TableCell>
        ) : (
          <>
            <TableCell
              sx={{
                borderBottomColor: 'transparent',
                backgroundColor: '#FAFAFA',
              }}
            >
              티커
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'transparent',
                backgroundColor: '#FAFAFA',
              }}
            >
              종목명
            </TableCell>
          </>
        )}

        <TableCell
          align="right"
          sx={{
            borderBottomColor: 'transparent',
            backgroundColor: '#FAFAFA',
          }}
        >
          비중
        </TableCell>
        <TableCell
          align="right"
          sx={{
            borderBottomColor: 'transparent',
            backgroundColor: '#FAFAFA',
          }}
        >
          편입여부
        </TableCell>
      </TableRow>
    );
  }, [isCompact]);

  const table = useMemo(() => {
    return filteredCompanies
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((company) => {
        const included = !some(
          excludedCompanies,
          (c) => c.id === company.cosmosCode,
        );
        const rebalancingInfo = rebalancingList.find(
          (r) => r.cosmosCode === company.cosmosCode,
        );
        const color = (theme: Theme) =>
          included ? theme.palette.grey['500'] : theme.palette.grey['300'];
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={company.cosmosCode}
          >
            {isCompact ? (
              <TableCell>
                <Typography
                  sx={{
                    color,
                  }}
                  variant="body1"
                >
                  {company.ticker}
                </Typography>
                <Stack direction="row" overflow="hidden">
                  <Typography
                    maxWidth="90px"
                    sx={{
                      color,
                    }}
                    variant="body2"
                    noWrap
                    textOverflow="ellipsis"
                  >
                    {company.companyLocalName &&
                    company.companyLocalName.length > 0
                      ? company.companyLocalName
                      : company.companyName}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{
                      color,
                    }}
                    onClick={() => {
                      window.open(
                        `/company-detail/${company.cosmosCode}`,
                        `company-detail-${company.cosmosCode}`,
                      );
                    }}
                    component="span"
                  >
                    <LaunchIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Stack>
              </TableCell>
            ) : (
              <>
                <TableCell>
                  <Typography
                    sx={{
                      color,
                    }}
                    variant="body1"
                  >
                    {company.ticker}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color,
                    }}
                    variant="caption"
                  >
                    {company.companyLocalName &&
                    company.companyLocalName.length > 0
                      ? company.companyLocalName
                      : company.companyName}
                  </Typography>

                  <IconButton
                    size="small"
                    sx={{
                      color,
                    }}
                    onClick={() => {
                      window.open(
                        `/company-detail/${company.cosmosCode}`,
                        `company-detail-${company.cosmosCode}`,
                      );
                    }}
                    component="span"
                  >
                    <LaunchIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </TableCell>
              </>
            )}
            <TableCell
              align="right"
              sx={{
                color,
              }}
            >
              {included && totalValue > 0
                ? `${(
                    ((rebalancingInfo?.weight ?? 0) / totalValue) *
                    100
                  ).toFixed(2)}%`
                : '0%'}
            </TableCell>
            <TableCell align="right">
              <Switch
                color="secondary"
                checked={included}
                onChange={(e) => onChange(e, company)}
              />
            </TableCell>
          </TableRow>
        );
      });
  }, [filteredCompanies, excludedCompanies, isCompact, page, rowsPerPage]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
      }}
      {...tableContainerProps}
    >
      <Table>
        {isCompact ? (
          <colgroup>
            <col width="60%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
        ) : (
          <colgroup>
            <col width="10%" />
            <col width="50%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
        )}
        <TableHead>{tableHeader}</TableHead>
        <TableBody>{table}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              colSpan={4}
              sx={{ px: '16px', py: 0, borderBottomColor: 'transparent' }}
            >
              <Stack direction="row" alignItems="center">
                {matchedCount ? (
                  <Typography>총 {matchedCount}개</Typography>
                ) : null}
                <Stack direction="row" flex={1} justifyContent="end">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 25, 50]}
                    count={filteredCompanies.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage=""
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      justifySelf: 'end',
                      borderBottomColor: 'transparent',
                    }}
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CompanyList;
