import React, { forwardRef, useCallback, useMemo } from 'react';
import { CustomLayerProps, ResponsiveLine } from '@nivo/line';
import {
  Box,
  Paper,
  Stack,
  Typography,
  LinearProgress,
  StackProps,
  Button,
} from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import * as xlsx from 'xlsx';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { clamp, groupBy, map, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import { useAmplitude } from 'react-amplitude-hooks';
import { useLocation } from 'react-router-dom';

import dayjs from '~/utils/dayjs';
import { Backtesting } from '~/api/backtesting';
import MonthlyYieldList from '~/components/strategy/creation/MonthlyYieldList';
import { getIsMobile } from '~/utils/mediaQuery';
import api from '~/api';

export function IndexSummery({
  title,
  delta,
  value,
  benchmarkName,
  ...stackProps
}: {
  title: string;
  delta?: number;
  value: number;
  benchmarkName?: string;
} & StackProps) {
  return (
    <Stack
      direction="column"
      p="24px"
      spacing={1}
      justifyContent="start"
      {...stackProps}
    >
      <Typography variant="body2">{title}</Typography>
      <Typography variant="h2" fontWeight="bold">
        {value.toFixed(2)}%
      </Typography>

      {delta ? (
        <Typography variant="overline">
          {`${benchmarkName ?? '벤치마크'} 대비 ${
            delta > 0 ? '+' : ''
          }${delta.toFixed(2)}%`}
        </Typography>
      ) : null}
    </Stack>
  );
}

IndexSummery.defaultProps = {
  delta: undefined,
  benchmarkName: undefined,
};
const PortBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: '#9E7DF9',
  },
}));

const BenchBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: '#D9DCE0',
  },
}));

BenchmarkCompareInfo.defaultProps = {
  unit: '',
  benchmarkName: undefined,
};
function BenchmarkCompareInfo({
  title,
  min,
  max,
  value,
  benchmarkName,
  benchmarkValue,
  unit,
  ...stackProps
}: {
  title: string;
  min: number;
  max: number;
  value: number;
  unit?: string;
  benchmarkName?: string;
  benchmarkValue: number;
} & StackProps) {
  const [filteredValue, filteredBenchmarkValue] = useMemo(() => {
    return [clamp(value, min, max), clamp(benchmarkValue, min, max)];
  }, [min, max, value, benchmarkValue]);

  return (
    <Stack direction="column" spacing={2} {...stackProps}>
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
      <Stack direction="row" width="100%">
        <Box flex={1}>
          <Typography
            variant="body2"
            fontSize="12px"
            color="rgba(0, 0, 0, 0.6)"
          >
            내 투자
          </Typography>
        </Box>
        <Typography
          variant="body2"
          fontSize="12px"
          alignSelf="end"
          textAlign="end"
        >
          {value.toFixed(2)}
          {unit}
        </Typography>
      </Stack>
      <PortBorderLinearProgress
        variant="determinate"
        sx={{
          background: 'rgba(0, 0, 0, 0.03)',
        }}
        value={Math.abs(filteredValue / (max - min)) * 100}
      />
      <Stack direction="row" width="100%">
        <Box flex={1}>
          <Typography
            variant="body2"
            fontSize="12px"
            color="rgba(0, 0, 0, 0.6)"
          >
            {benchmarkName ?? '벤치마크'}
          </Typography>
        </Box>
        <Typography variant="body2" fontSize="12px" textAlign="end">
          {benchmarkValue.toFixed(2)}
          {unit}
        </Typography>
      </Stack>
      <BenchBorderLinearProgress
        variant="determinate"
        sx={{
          background: 'rgba(0, 0, 0, 0.03)',
        }}
        value={Math.abs(filteredBenchmarkValue / (max - min)) * 100}
      />
    </Stack>
  );
}

interface BacktestingResultProp {
  strategyName?: string;
  backtestingResult: Backtesting;
}

const BacktestingResult = forwardRef(function (
  {
    strategyName,
    backtestingResult,
    ...stackProps
  }: BacktestingResultProp & StackProps,
  ref,
) {
  const [t] = useTranslation();
  const isMobile = getIsMobile();
  const location = useLocation();
  const { logEvent } = useAmplitude();

  const benchmarkName = useMemo(() => {
    return backtestingResult.benchmarkInfo?.name;
  }, [backtestingResult]);

  const [bmNodes, portNodes, bmMddNodes, portMddNodes] = useMemo(() => {
    if (!backtestingResult) {
      return [];
    }
    // 데이터 가공

    return (
      backtestingResult?.yieldIndex?.portBMYieldIndexes?.reduce?.(
        (r, node, i) => {
          if (i === 0) {
            const startDate = dayjs(node.startDate)
              .add(1, 'day')
              .format('YYYY-MM-DD');
            r.forEach((list) => {
              list.push({
                x: startDate,
                y: 0,
              });
            });
          }
          const x = dayjs(node.endDate).format('YYYY-MM-DD');
          r[0].push({
            x,
            y: (node.bmIndex - 1) * 100,
          });
          r[1].push({
            x,
            y: (node.portIndex - 1) * 100,
          });
          r[2].push({
            x,
            y: node.bmMdd * 100,
          });
          r[3].push({
            x,
            y: node.portMdd * 100,
          });
          return r;
        },
        [[], [], [], []] as { x: string; y: number }[][],
      ) ?? []
    );
  }, [backtestingResult]);

  const indexData = useMemo(() => {
    return [
      {
        id: 'bm',
        color: '#D9DCE0',
        data: bmNodes,
      },
      {
        id: 'port',
        color: '#9E7DF9',
        data: portNodes,
      },
    ];
  }, [bmNodes, portNodes]);

  const mddData = useMemo(() => {
    return [
      {
        id: 'bmMdd',
        color: '#D9DCE0',
        data: bmMddNodes,
      },
      {
        id: 'portMdd',
        color: '#9E7DF9',
        data: portMddNodes,
      },
    ];
  }, [bmMddNodes, portMddNodes]);

  // const factorDescription = useMemo(() => {
  //   return (
  //     <Paper
  //       sx={{
  //         borderRadius: '8px',
  //         boxShadow:
  //           '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
  //       }}
  //     >
  //       <Stack direction="column" p={2} spacing={1}>
  //         <Typography variant="h5" fontWeight="bold">
  //           백테스트 요약
  //         </Typography>
  //         {map(
  //           groupBy(backtestingResult.factorFilter, (f) => f.factorId),
  //           (filters, factorId: number) => {
  //             return (
  //               <Typography
  //                 key={factorId}
  //                 variant="body2"
  //                 color="text.secondary"
  //               >
  //                 {t(`factor.${factorId}.name`)}:{' '}
  //                 {filters
  //                   .filter((v) => typeof v.value === 'number')
  //                   // @ts-ignore
  //                   .sort((v) => v.value ?? 0)
  //                   .map((filter) => {
  //                     return `x ${filter.symbol} ${filter.value}`;
  //                   })
  //                   .join(', ')}
  //               </Typography>
  //             );
  //           },
  //         )}
  //       </Stack>
  //     </Paper>
  //   );
  // }, [backtestingResult]);

  function CustomPoint(props: CustomLayerProps) {
    /* @ts-ignore */
    const { currentSlice, pointBorderWidth, pointBorderColor } = props;
    // it will show the current point
    if (currentSlice) {
      /* @ts-ignore */
      return currentSlice.points.map((point) => {
        return (
          <g key={point.id}>
            <circle r={4} fill={point.color} cx={point.x} cy={point.y} />
            <circle r={4} fill={point.color} cx={point.x} cy={point.y} />
          </g>
        );
      });
    }
    return <div />;
  }

  const downloadXlsx = useCallback(() => {
    if (!backtestingResult) {
      return;
    }
    function stringToArrayBuffer(string: string) {
      const buffer = new ArrayBuffer(string.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < string.length; i += 1)
        // eslint-disable-next-line no-bitwise
        view[i] = string.charCodeAt(i) & 0xff;
      return buffer;
    }

    const summarySheet = xlsx.utils.json_to_sheet([
      pick(backtestingResult, [
        'id',
        'nationCode',
        'indexCode',
        'startDate',
        'endDate',
        'categoryIds',
        'count',
        'excludedCompanies',
        'rebalancingPeriod',
      ]),
    ]);
    xlsx.utils.sheet_add_json(
      summarySheet,
      [
        {
          type: 'port',
          ...backtestingResult.yieldIndex?.portStats,
        },
        {
          type: 'bm',
          ...backtestingResult.yieldIndex?.bmStats,
        },
      ],
      {
        origin: xlsx.utils.encode_cell({ c: 0, r: 3 }),
      },
    );

    const factorFilterSheet = xlsx.utils.json_to_sheet(
      backtestingResult.factorFilter ?? [],
    );
    const factorQuerySheet = xlsx.utils.json_to_sheet(
      backtestingResult.factorQuery ?? [],
    );
    const rebalancingSheet = xlsx.utils.json_to_sheet(
      backtestingResult.rebalancingResult?.companyRebalancingInfos ?? [],
    );
    const companyInfoSheet = xlsx.utils.json_to_sheet(
      backtestingResult.rebalancingResult?.companyInfos ?? [],
    );
    const benchmarkHistorySheet = xlsx.utils.json_to_sheet(
      backtestingResult.yieldIndex?.portBMYieldIndexes ?? [],
    );

    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, summarySheet, 'summary');
    xlsx.utils.book_append_sheet(workbook, factorFilterSheet, 'factor-filter');
    xlsx.utils.book_append_sheet(workbook, factorQuerySheet, 'factor-query');
    xlsx.utils.book_append_sheet(workbook, rebalancingSheet, 'rebalancing');
    xlsx.utils.book_append_sheet(workbook, companyInfoSheet, 'company');
    xlsx.utils.book_append_sheet(workbook, benchmarkHistorySheet, 'return');

    const workbookOutput = xlsx.write(workbook, {
      type: 'binary',
      bookType: 'xlsx',
    });
    const blob = new Blob([stringToArrayBuffer(workbookOutput)], {
      type: 'application/octet-stream',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `backtesting-${backtestingResult.uid}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }, [backtestingResult]);

  const downloadMonthlyReport = useCallback(() => {
    if (!backtestingResult?.uid) {
      return;
    }
    logEvent?.('backtesting portfolio btn clicked', {
      'page url': location.pathname,
    });

    api.backtesting
      .downloadBacktestingMonthlyReportXlsx(backtestingResult?.uid)
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${[
          'Tailor_Backtesting_Portfolio',
          strategyName ?? '',
          dayjs().format('YYYYMMDD_HHmmss'),
        ].join('_')}.xlsx`;
        anchor.click(); // anchor를 다운로드 링크로 만들고 강제로 클릭 이벤트 발생
        window.URL.revokeObjectURL(url); // 메모리에서 해제
      })
      .catch((e) => console.error('fail', e));
  }, [backtestingResult]);

  return (
    <Stack
      ref={ref}
      // width="100%"
      height="fit-content"
      direction="column"
      spacing="32px"
      {...stackProps}
    >
      <Box component="span" mt="4px" alignItems="center" whiteSpace="pre-wrap">
        <Typography component="span" variant="body2" color="text.secondary">
          본 백테스팅 결과에는 생존 편향
        </Typography>
        <Tooltip
          title="생존 편향(Survivorship Bias)이란, 상장폐지된 기업들은 고려하지 않은 채 생존한 기업들만 고려하여 과거 수익률이 높아 보이는 오류입니다."
          arrow
          placement="bottom"
        >
          <ErrorOutline
            sx={{
              marginLeft: '4px',
              width: '14px',
              height: '14px',
              color: 'text.secondary',
            }}
          />
        </Tooltip>
        <Typography component="span" variant="body2" color="text.secondary">
          , 미래 참조 편향
        </Typography>
        <Tooltip
          title="미래 참조 편향(Look-ahead Bias)이란, 정보가 공개되는 시점을 고려하지 않아 기업들의 정보를 미리 확인하는 것처럼 처리되어 과거 수익률이 높아 보이는 오류입니다."
          arrow
          placement="bottom"
        >
          <ErrorOutline
            sx={{
              marginLeft: '4px',
              marginRight: '4px',
              width: '14px',
              height: '14px',
              color: 'text.secondary',
            }}
          />
        </Tooltip>
        <Typography component="span" variant="body2" color="text.secondary">
          이 없습니다.
        </Typography>
      </Box>
      {process.env.NODE_ENV !== 'production' ||
      process.env.REACT_APP_ENV !== 'production' ? (
        <Button
          fullWidth
          variant="contained"
          size="small"
          color="warning"
          onClick={downloadXlsx}
        >
          Excel 다운로드
        </Button>
      ) : null}
      {/* {factorDescription} */}
      <Stack
        width="100%"
        height={['calc(80vw + 220px)', '350px']}
        direction={['column', 'row']}
        p={2}
      >
        <Stack flex={1} height="100%" direction="column" spacing={1}>
          <Stack
            height="32px"
            direction="row"
            alignItems="center"
            spacing={1}
            pr={1}
          >
            <Typography flex={1} variant="h5" fontWeight="bold">
              누적 수익률
            </Typography>
            <Box
              sx={{
                width: '6px',
                height: '6px',
                borderRadius: '3px',
                backgroundColor: '#9E7DF9',
              }}
            />
            <Typography fontSize="12px">내 투자</Typography>
            <Box
              sx={{
                width: '6px',
                height: '6px',
                borderRadius: '3px',
                backgroundColor: '#D9DCE0',
              }}
            />
            <Typography fontSize="12px">
              {t(`benchmark.${benchmarkName}`)}
            </Typography>
          </Stack>
          <Box
            width="100%"
            height={['80vw', 'calc(100% - 32px)']}
            minWidth="0px"
          >
            {/* @ts-ignore */}
            <ResponsiveLine
              data={indexData}
              margin={{ top: 24, right: 24, bottom: 24, left: 36 }}
              curve="catmullRom"
              lineWidth={3}
              colors={{ datum: 'color' }}
              enableGridX={false}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
              }}
              // eslint-disable-next-line react/no-unstable-nested-components
              layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'slices',
                'points',
                'mesh',
                'legends',
                CustomPoint,
              ]}
              enablePoints={false}
              enableSlices="x"
              sliceTooltip={({ slice }) => (
                <Box
                  // @ts-ignore
                  key={slice.id}
                  bgcolor="background.paper"
                  p="8px"
                  sx={{
                    borderRadius: '8px',
                    boxShadow:
                      '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
                  }}
                >
                  <div>{slice.points[0].data.xFormatted}</div>
                  {slice.points.map((point, i) => {
                    return (
                      <Stack key={point.id} direction="row" alignItems="center">
                        <Box
                          sx={{
                            width: '6px',
                            height: '6px',
                            borderRadius: '3px',
                            backgroundColor: point.color,
                            marginRight: '8px',
                          }}
                        />
                        <Typography color="text.secondary" marginRight="8px">
                          {i === 0
                            ? '내 투자'
                            : t(`benchmark.${benchmarkName}`)}
                        </Typography>

                        <Typography
                          color="text.primary"
                          marginRight="8px"
                          fontWeight="bold"
                        >
                          {point?.data?.y.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                          %
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              )}
              yScale={{
                type: 'linear',
                stacked: false,
                min: Math.min(
                  -20,
                  ...indexData.flatMap((chart) => chart.data.map((d) => d.y)),
                ),
              }}
              xFormat="time:%Y-%m-%d"
              axisLeft={{
                legendOffset: 12,
                format: (v) => `${v}%`,
              }}
              axisBottom={{
                format: '%Y-%m',
                tickValues: 'every 1 year',
              }}
              useMesh
              theme={{ textColor: '#888888' }}
            />
          </Box>
        </Stack>
        <Stack
          direction={['row', 'column']}
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IndexSummery
            sx={{
              color: '#9E7DF9',
              width: '100%',
              height: ['130px', null],
              flex: 1,
              borderRadius: ['0px 8px 8px 0px', '8px'],
              backgroundColor: 'rgba(236, 233, 241, 0.4)',
            }}
            title="내 투자"
            delta={
              (backtestingResult.yieldIndex?.portStats?.accumYield ?? 0) * 100 -
              (backtestingResult.yieldIndex?.bmStats?.accumYield ?? 0) * 100
            }
            value={
              (backtestingResult.yieldIndex?.portStats?.accumYield ?? 0) * 100
            }
            benchmarkName={t(`benchmark.${benchmarkName}`)}
          />
          <IndexSummery
            sx={{
              mt: [0, '18px'],
              ml: ['18px', 0],
              color: 'rgba(0, 0, 0, 0.6);',
              width: '100%',
              height: ['130px', null],
              flex: 1,
              borderRadius: ['0px 8px 8px 0px', '8px'],
              backgroundColor: 'rgba(236, 233, 241, 0.4)',
            }}
            title={t(`benchmark.${benchmarkName}`)}
            value={
              (backtestingResult.yieldIndex?.bmStats?.accumYield ?? 0) * 100
            }
            benchmarkName={t(`benchmark.${benchmarkName}`)}
          />
        </Stack>
      </Stack>
      <Stack
        width="100%"
        height={['calc(80vw + 220px)', '350px']}
        direction={['column', 'row']}
        p={2}
      >
        <Stack flex={1} height="100%" direction="column" spacing={1}>
          <Stack
            height="32px"
            direction="row"
            alignItems="center"
            spacing={1}
            pr={1}
          >
            <Typography flex={1} variant="h5" fontWeight="bold">
              최대 손실 (MDD)
            </Typography>
            <Box
              sx={{
                width: '6px',
                height: '6px',
                borderRadius: '3px',
                backgroundColor: '#9E7DF9',
              }}
            />
            <Typography fontSize="12px">내 투자</Typography>
            <Box
              sx={{
                width: '6px',
                height: '6px',
                borderRadius: '3px',
                backgroundColor: '#D9DCE0',
              }}
            />
            <Typography fontSize="12px">
              {t(`benchmark.${benchmarkName}`)}
            </Typography>
          </Stack>
          <Box
            width="100%"
            height={['80vw', 'calc(100% - 32px)']}
            minWidth="0px"
          >
            {/* @ts-ignore */}
            <ResponsiveLine
              data={mddData}
              margin={{ top: 24, right: 24, bottom: 24, left: 36 }}
              curve="catmullRom"
              lineWidth={3}
              colors={{ datum: 'color' }}
              enableGridX={false}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
              }}
              layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'slices',
                'points',
                'mesh',
                'legends',
                CustomPoint,
              ]}
              enablePoints={false}
              enableSlices="x"
              sliceTooltip={({ slice }) => (
                <Box
                  // @ts-ignore
                  key={slice.id}
                  bgcolor="background.paper"
                  p="8px"
                  sx={{
                    borderRadius: '8px',
                    boxShadow:
                      '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
                  }}
                >
                  <div>{slice.points[0].data.xFormatted}</div>
                  {slice.points.map((point, i) => {
                    return (
                      <Stack key={point.id} direction="row" alignItems="center">
                        <Box
                          sx={{
                            width: '6px',
                            height: '6px',
                            borderRadius: '3px',
                            backgroundColor: point.color,
                            marginRight: '8px',
                          }}
                        />
                        <Typography color="text.secondary" marginRight="8px">
                          {i === 0
                            ? '내 투자'
                            : t(`benchmark.${benchmarkName}`)}
                        </Typography>

                        <Typography
                          color="text.primary"
                          marginRight="8px"
                          fontWeight="bold"
                        >
                          {point?.data?.y.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                          %
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              )}
              yScale={{
                type: 'linear',
                stacked: false,
                min: Math.min(
                  -20,
                  ...mddData.flatMap((chart) => chart.data.map((d) => d.y)),
                ),
                max: 0,
              }}
              xFormat="time:%Y-%m-%d"
              axisLeft={{
                legendOffset: 12,
                format: (v) => `${v}%`,
              }}
              axisBottom={{
                format: '%Y-%m',
                tickValues: 'every 1 year',
              }}
              useMesh
              theme={{ textColor: '#888888' }}
            />
          </Box>
        </Stack>
        <Stack
          direction={['row', 'column']}
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IndexSummery
            sx={{
              color: '#9E7DF9',
              width: '100%',
              height: ['130px', null],
              flex: 1,
              borderRadius: ['0px 8px 8px 0px', '8px'],
              backgroundColor: 'rgba(236, 233, 241, 0.4)',
            }}
            title="내 투자"
            delta={
              (backtestingResult.yieldIndex?.portStats.minMdd ?? 0) * 100 -
              (backtestingResult.yieldIndex?.bmStats.minMdd ?? 0) * 100
            }
            value={(backtestingResult.yieldIndex?.portStats.minMdd ?? 0) * 100}
            benchmarkName={t(`benchmark.${benchmarkName}`)}
          />
          <IndexSummery
            sx={{
              mt: [0, '18px'],
              ml: ['18px', 0],
              color: 'rgba(0, 0, 0, 0.6);',
              width: '100%',
              height: ['130px', null],
              flex: 1,
              borderRadius: ['0px 8px 8px 0px', '8px'],
              backgroundColor: 'rgba(236, 233, 241, 0.4)',
            }}
            title={t(`benchmark.${benchmarkName}`)}
            value={(backtestingResult.yieldIndex?.bmStats.minMdd ?? 0) * 100}
          />
        </Stack>
      </Stack>
      <Stack
        direction={['column', 'row']}
        sx={{
          '> :not(:first-of-type)': {
            ml: [0, '20px'],
            mt: ['20px', 0],
          },
        }}
      >
        <BenchmarkCompareInfo
          sx={{
            flex: 1,
            p: '24px',
            borderRadius: '8px',
            boxShadow:
              '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
          }}
          title="샤프 비율"
          value={backtestingResult.yieldIndex?.portStats.annualSharp ?? 0}
          benchmarkName={t(`benchmark.${benchmarkName}`)}
          benchmarkValue={
            backtestingResult.yieldIndex?.bmStats.annualSharp ?? 0
          }
          min={0}
          max={2}
        />
        <BenchmarkCompareInfo
          sx={{
            flex: 1,
            p: '24px',
            borderRadius: '8px',
            boxShadow:
              '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
          }}
          title="연율화 수익률"
          value={
            (backtestingResult.yieldIndex?.portStats.annualYield ?? 0) * 100
          }
          benchmarkName={t(`benchmark.${benchmarkName}`)}
          benchmarkValue={
            (backtestingResult.yieldIndex?.bmStats.annualYield ?? 0) * 100
          }
          unit="%"
          min={0}
          max={30}
        />
        <BenchmarkCompareInfo
          sx={{
            flex: 1,
            p: '24px',
            borderRadius: '8px',
            boxShadow:
              '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
          }}
          title="연율화 변동성"
          value={
            (backtestingResult.yieldIndex?.portStats?.annualStdev ?? 0) * 100
          }
          benchmarkName={t(`benchmark.${benchmarkName}`)}
          benchmarkValue={
            (backtestingResult.yieldIndex?.bmStats.annualStdev ?? 0) * 100
          }
          unit="%"
          min={0}
          max={30}
        />
      </Stack>
      <MonthlyYieldList
        width="100%"
        py="16px"
        pl={[null, '16px']}
        px={['20px', null]}
        backtestingResult={backtestingResult}
        displayingAllYears={!isMobile}
        onDownloadXlsx={() => downloadMonthlyReport()}
      />
    </Stack>
  );
});

BacktestingResult.displayName = 'BacktestingResult';
BacktestingResult.defaultProps = {
  strategyName: undefined,
};

export default BacktestingResult;
