import {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Grid, Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

import IndexedTab from '~/components/IndexedTab';
import { get } from '~/utils/localStorage';
import {
  BackTestDispatchContext,
  BackTestReducer,
  BackTestStateContext,
} from '~/containers/backtest/context';
import BacktestOptionContainer from '~/containers/backtest/BacktestOptionContainer';
import ConditionsStorageContainer from '~/containers/conditions/ConditionsStorageContainer';
import FactorListContainer from '~/containers/factor/FactorList';
import FactorHistogramContainer from '~/containers/factor/FactorHistogramContainer';
import BacktestingHeader from '~/containers/backtest/BacktestingHeader';
import { AppContext } from '~/AppContext';
import BacktestingExecButton from '~/containers/backtest/BacktestingExecButton';
import StyledButton from '~/components/StyledButton';

type BackTestProviderProps = {
  children: ReactElement;
};
function BackTestProvider({ children }: BackTestProviderProps) {
  const [backtestState, backtestDispatch] = useReducer(BackTestReducer, {
    startDate: new Date(
      // @ts-ignore
      parseInt(get('BACKTEST_START_DATETIME') ?? '1107244432000', 10),
    ),

    endDate: new Date(
      parseInt(
        // @ts-ignore
        get('BACKTEST_END_DATETIME') ?? new Date().getTime().toString(),
        10,
      ),
    ),
    // @ts-ignore
    tradeCost: parseFloat(get('BACKTEST_TRADE_COST') ?? '0.6'),
    // @ts-ignore
    top: parseInt(get('BACKTEST_TOP') ?? '30', 10),
    // @ts-ignore
    rebalancingPeriod: get('BACKTEST_REBALANCINGPERIOD') ?? 'ANNUALLY',
  });

  return (
    <BackTestStateContext.Provider value={backtestState}>
      <BackTestDispatchContext.Provider value={backtestDispatch}>
        {children}
      </BackTestDispatchContext.Provider>
    </BackTestStateContext.Provider>
  );
}

function BackTestView() {
  function BacktestingBody() {
    const [t] = useTranslation();
    const backtestDispatch = useContext(BackTestDispatchContext);

    return (
      <>
        <Grid container display={['none', 'flex']} width="100%" columns={8}>
          <Grid item xs={2} sm={2} overflow="scroll" height="100%">
            <Box bgcolor="#FCFCFC" paddingBottom="300px">
              <BacktestOptionContainer />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '36px',
                width: 'fit-content',
                zIndex: 2,
              }}
            >
              <ConditionsStorageContainer
                isFullScreen={false}
                onUpdate={() => {
                  backtestDispatch?.({
                    type: 'UPDATE_BACKTEST_VALUES',

                    startDate: new Date(
                      parseInt(
                        // @ts-ignore
                        get('BACKTEST_START_DATETIME') ?? '1107244432000',
                        10,
                      ),
                    ),

                    endDate: new Date(
                      parseInt(
                        // @ts-ignore
                        get('BACKTEST_END_DATETIME') ??
                          new Date().getTime().toString(),

                        10,
                      ),
                    ),
                    // @ts-ignore
                    tradeCost: parseFloat(get('BACKTEST_TRADE_COST') ?? '0.6'),
                    // @ts-ignore
                    top: parseInt(get('BACKTEST_TOP') ?? '30', 10),
                    rebalancingPeriod:
                      // @ts-ignore
                      get('BACKTEST_REBALANCINGPERIOD') ?? 'ANNUALLY',
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} overflow="hidden" height="100%">
            <Grid
              container
              width="100%"
              height="100%"
              overflow="hidden"
              columns={6}
            >
              <Grid
                item
                xs={2}
                sm={2}
                overflow="scroll"
                bgcolor="white"
                height="100%"
              >
                <FactorListContainer />
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                overflow="scroll"
                bgcolor="white"
                height="100%"
              >
                <FactorHistogramContainer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display={['inherit', 'none']} width="100%">
          <IndexedTab
            hasStorageButton
            lastActionButton={
              <>
                <StyledButton
                  sx={{
                    flex: 1,
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(window.location.href)
                      .finally(() => {
                        alert('URL 링크가 복사되었습니다.');
                      });
                  }}
                >
                  전략 공유
                </StyledButton>
                <BacktestingExecButton sx={{ flex: 1 }} />
              </>
            }
            items={[
              {
                title: `1. ${t('text.defaultSettings')}`,
                child: <BacktestOptionContainer />,
              },
              {
                title: `2. ${t('text.factorSettings')}`,
                child: <FactorListContainer />,
              },
              {
                title: `3. ${t('text.factorDetailSettings')}`,
                child: <FactorHistogramContainer />,
              },
            ]}
          />
        </Box>
      </>
    );
  }

  const { state } = useContext(AppContext) ?? {};
  const location = useLocation<{ error?: string }>();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.error) {
      state?.showToast('error', location.state.error, {
        position: 'bottom-left',
      });
      history.replace('backtest', { ...location.state, error: undefined });
    }
  }, [location?.state?.error]);

  return (
    <BackTestProvider>
      <Flex
        h={['-webkit-fill-available', '100%']}
        w="100%"
        flexDirection="column"
      >
        <BacktestingHeader />
        <Flex h={['calc(100vh - 150px)', 'calc(100vh - 156px)']}>
          <BacktestingBody />
        </Flex>
      </Flex>
    </BackTestProvider>
  );
}

export default BackTestView;
