import React, { useContext, useState } from 'react';
import { Box, Grid, Stack, StackProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

import { Index, Nation } from '~/api/strategy';
import SelectableStackItem from '~/components/strategy/creation/SelectableStackItem';
import { AppContext } from '~/AppContext';
import { ReactComponent as FtseIcon } from '~/assets/icon/logo_ftse.svg';
import { ReactComponent as IconNew } from '~/assets/icon/icon_new.svg';
import { ReactComponent as IconNewDot } from '~/assets/icon/icon_new_dot.svg';

export type Universe =
  | ({ universe: 'index' } & Index)
  | ({ universe: 'nation' } & Nation);

type UniverseListProps = {
  universes?: Universe[];
  value?: Universe | null;
  onChangeUniverse?: (universe: Universe | undefined) => void;
} & StackProps;

function UniverseList({
  universes,
  value,
  onChangeUniverse,
  ...stackProps
}: UniverseListProps) {
  // nation id?
  const [t] = useTranslation();
  const { state } = useContext(AppContext) ?? {};
  const { logEvent } = useAmplitude();

  const indexList = universes?.filter((v) => v.universe === 'index') ?? [];
  const nationList = universes?.filter((v) => v.universe === 'nation') ?? [];
  const enabledIndexCodes = [
    7736, 4408, 409, 8007, 3900, 7732, 3876, 4139, 4141, 2188, 7119, 6522,
  ];

  return (
    <Stack {...stackProps} direction="column" spacing="40px">
      <Box flex={1} width="100%" overflow="auto">
        <Stack direction="column" spacing="14px">
          <Stack direction="row" alignItems="center">
            <Typography variant="headline2">지수</Typography>
            <IconNew style={{ marginLeft: '4px' }} />
          </Stack>
          <Grid width="100%" container columns={2}>
            {indexList?.map((index, i) => {
              const selected =
                index.universe === value?.universe &&
                index.code === value?.code;
              const isDisabled = !enabledIndexCodes.includes(index.code);
              return (
                <Grid
                  key={`universe-index-${index.code}`}
                  item
                  xs={2}
                  sm={index.code === 6522 ? 2 : 1}
                  mb="8px"
                  paddingRight={i % 2 === 1 ? '4px' : '4px'}
                  paddingLeft={i % 2 === 0 ? '4px' : '4px'}
                >
                  <Box position="relative">
                    <SelectableStackItem
                      key={`${index.universe}-${index.code}`}
                      selected={selected}
                      disabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) {
                          return;
                        }
                        logEvent('univ basket card clicked', {
                          'selected universe': index.name,
                        });
                        onChangeUniverse?.(index);
                      }}
                    >
                      <>
                        <Stack direction="row" alignItems="center">
                          <Stack flex={1} direction="row" alignItems="top">
                            <Typography
                              sx={{ fontWeight: 'bold' }}
                              variant="body1"
                            >
                              {t(`index.${index.name}.name`)}
                            </Typography>
                            {index.code === 6522 ? (
                              <IconNewDot style={{ marginLeft: '4px' }} />
                            ) : null}
                          </Stack>

                          {index.code === 6522 ? (
                            <FtseIcon />
                          ) : (
                            <Box p="4px" bgcolor="#E8F6DC" borderRadius="4px">
                              <Typography
                                color="#66CC66"
                                fontWeight="bold"
                                variant="body2"
                              >
                                {(index as Index)?.region}
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                        <Typography variant="body2" color="text.secondary">
                          {t(`index.${index.name}.description`)}
                        </Typography>
                      </>
                    </SelectableStackItem>
                    {isDisabled ? (
                      <Box
                        top="0px"
                        borderRadius="8px"
                        paddingRight={i % 2 === 1 ? '4px' : '4px'}
                        paddingLeft={i % 2 === 0 ? '4px' : '4px'}
                        width="100%"
                        height="100%"
                        zIndex={1000}
                        position="absolute"
                        bgcolor="rgba(255, 255, 255, 0.75)"
                      >
                        <Typography
                          position="absolute"
                          variant="body2"
                          bottom="4px"
                          right="4px"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          coming soon 😋
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Typography variant="headline2">시장</Typography>
          <Grid width="100%" container columns={2}>
            {nationList?.map((index, i) => {
              const selected =
                index.universe === value?.universe &&
                index.code === value?.code;

              return (
                <Grid
                  key={`universe-nation-${index.code}`}
                  item
                  xs={2}
                  sm={1}
                  mb="8px"
                  paddingRight={i % 2 === 1 ? '4px' : '4px'}
                  paddingLeft={i % 2 === 0 ? '4px' : '4px'}
                >
                  <SelectableStackItem
                    key={`${index.universe}-${index.code}`}
                    selected={selected}
                    onClick={() => {
                      logEvent('univ basket card clicked', {
                        'selected universe': index.name,
                      });

                      onChangeUniverse?.(index);
                    }}
                  >
                    <Stack direction="row" width="100%">
                      <Typography
                        sx={{ flex: 1, fontWeight: 'bold' }}
                        variant="body1"
                      >
                        {t(`nation.${index.name}.name`)}
                      </Typography>
                      <Typography>{t(`nation.${index.name}.flag`)}</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                      {t(`nation.${index.name}.description`)}
                    </Typography>
                  </SelectableStackItem>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Box>
    </Stack>
  );
}

UniverseList.defaultProps = {
  universes: [],
  value: null,
  onChangeUniverse: null,
};

export default UniverseList;
