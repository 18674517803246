import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAmplitude } from 'react-amplitude-hooks';

import dayjs from '~/utils/dayjs';
import FactorPresetList, {
  FactorPreset,
} from '~/components/strategy/creation/FactorPresetList';
import StyledButton from '~/components/StyledButton';
import TailorCaseCardImage1 from '~/assets/image/tailor_case_card_img1.svg';
import TailorCaseCardImage2 from '~/assets/image/tailor_case_card_img2.svg';
import TailorCaseCardImage3 from '~/assets/image/tailor_case_card_img3.svg';
import TailorCaseCardImage4 from '~/assets/image/tailor_case_card_img4.svg';
import TailorCaseCardImage5 from '~/assets/image/tailor_case_card_img5.svg';
import { ReactComponent as IconVanguard } from '~/assets/icon/icon_vanguard.svg';
import FtseBackground from '~/assets/icon/ftse_background.svg';
import FtsePattern from '~/assets/icon/ftse_pattern.svg';

export const lv1Presets: FactorPreset[] = [
  {
    id: 5,
    recommended: 100,
    universe: {
      universe: 'index',
      name: 'S&P 500',
      code: 4408,
      region: 'US',
      currency: 'USD',
      nationCode: 840,
    },
    nameTextSpans: [{ text: '헬스케어 성장 집중 전략' }],
    description:
      '헬스케어 산업의 특징은 성장을 지속하는 것에 있습니다. 성장 모멘텀 스코어를 활용하여 수익률과 리스크를 동시에 개선한 전략입니다. ',

    articleUrl:
      'https://www.tailor.im/blog/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=12648334&t=board',
    factors: [
      {
        id: 10,
        min: 8,
        max: 13,
      },
    ],
    excludedCategoryIds: [
      5115, 5125, 5135, 5215, 5225, 5235, 531517, 531527, 5325, 5345, 5415,
      5425, 543517, 543527, 5445, 5515, 5525, 5535, 5545, 5615, 5625, 5635,
      5655, 5665, 581517, 581527, 581547, 581557, 581567, 581577, 5825, 5835,
      5845, 6015, 6115, 6215, 6315, 6415,
    ],
    count: 500,
    startDate: dayjs.tz('2014-02', 'Asia/seoul').startOf('month').toDate(),
    endDate: dayjs.tz('2022-06', 'Asia/seoul').endOf('month').toDate(),
    excludedCompanies: [],
    image: TailorCaseCardImage5,
    tradeCost: 0.4,
    tags: ['🔥'],
  },
  {
    id: 2,
    recommended: 100,
    universe: {
      universe: 'nation',
      code: 410,
      name: 'KOREA (SOUTH)',
    },
    nameTextSpans: [{ text: '피터린치 중소형주 성장 집중 전략' }],
    description:
      '중소형주 특징인 자금유동성 리스크를 줄이고, 건강한 성장 지표로 매출 성장률에 집중하는 피터린치 스코어를 통해 대한민국 중소형주를 공략한 전략입니다. ',
    articleUrl:
      'https://www.tailor.im/blog/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=12257094&t=board',
    factors: [
      {
        id: 3,
        min: 7,
        max: 10,
      },
      {
        id: 1133,
        min: 250,
        max: 500,
      },
    ],
    excludedCategoryIds: [],
    count: 20,

    startDate: dayjs.tz('2011-02', 'Asia/seoul').startOf('month').toDate(),
    endDate: dayjs.tz('2022-04', 'Asia/seoul').endOf('month').toDate(),
    tags: ['🔥'],
    image: TailorCaseCardImage2,
    tradeCost: 0.4,
  },
  {
    id: 3,
    recommended: 100,
    universe: {
      universe: 'nation',
      code: 410,
      name: 'KOREA (SOUTH)',
    },
    nameTextSpans: [{ text: '테크 성장주 리스크 감소 전략' }],
    description: `고밸류에이션 산업인 테크 분야에서 마틴 즈웨이그의 이익 모멘텀 스코어를 통해, 좀 더 안정적인 기업을 찾아 성장성을 가져가면서도 변동성을 줄이는 전략입니다. `,
    articleUrl:
      'https://www.tailor.im/blog/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=12452393&t=board',
    factors: [
      {
        id: 5,
        min: 7,
        max: 11,
      },
    ],
    excludedCategoryIds: [
      5115, 5125, 5135, 5215, 5225, 5235, 531517, 531527, 5325, 5345, 5415,
      5425, 543517, 543527, 5445, 5515, 5525, 5535, 5545, 5615, 5625, 5635,
      5655, 5665, 5715, 5725, 581517, 581527, 581547, 581557, 581567, 581577,
      5835, 5845, 6015, 6115, 6215, 6315, 6415,
    ],
    count: 10,
    startDate: dayjs.tz('2019-02', 'Asia/seoul').startOf('month').toDate(),
    endDate: dayjs.tz('2022-06', 'Asia/seoul').endOf('month').toDate(),

    image: TailorCaseCardImage3,
    tradeCost: 0.4,

    tags: ['🧘‍'],
  },
  {
    id: 4,
    recommended: 100,
    universe: {
      universe: 'nation',
      code: 410,
      name: 'KOREA (SOUTH)',
    },
    nameTextSpans: [{ text: '반도체 산업 수익 극대화 전략' }],
    description: `반도체 산업에 안정성으로 대표되는 배당을 기준으로, 부채비율이 적고 재무적으로 건강한 기업을 찾아 수익성을 극대화한 전략입니다.`,
    articleUrl:
      'https://www.tailor.im/blog/?q=YToyOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjtzOjQ6InBhZ2UiO2k6MTt9&bmode=view&idx=12583442&t=board',
    factors: [
      {
        id: 1027,
        min: 2,
        max: 20,
      },
      {
        id: 1133,
        min: 50,
        max: 5000000,
      },
    ],
    excludedCategoryIds: [
      5115, 5125, 5135, 5215, 5225, 5235, 531517, 531527, 5325, 5345, 5415,
      5425, 543517, 543527, 5445, 5515, 5525, 5535, 5545, 5615, 5625, 5635,
      5655, 5665, 5715, 5725, 581527, 581547, 581557, 581567, 581577, 5825,
      5835, 5845, 6015, 6115, 6215, 6315, 6415,
    ],
    count: 20,
    startDate: dayjs.tz('2019-02', 'Asia/seoul').startOf('month').toDate(),
    endDate: dayjs.tz('2022-06', 'Asia/seoul').endOf('month').toDate(),

    image: TailorCaseCardImage4,
    tradeCost: 0.4,

    tags: ['🔥'],
  },
  {
    id: 1,
    recommended: 100,
    universe: {
      universe: 'nation',
      code: 410,
      name: 'KOREA (SOUTH)',
    },
    nameTextSpans: [
      { text: '은행ETF보다 ' },
      { text: '안정적 & 배당 ', isBold: true },
      { text: '개선 ' },
    ],
    description:
      '은행 분야에 성장주 특성을 가진 카카오뱅크를 제외해서, 안정적 주가 흐름과 배당 수익을 살린 테일러 케이스입니다.',

    articleUrl:
      'https://www.tailor.im/blog/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=12256914&t=board',
    factors: [],
    excludedCategoryIds: [
      5115, 5125, 5135, 5215, 5225, 5235, 531517, 531527, 5325, 5345, 5415,
      5425, 543517, 543527, 5445, 5515, 5525, 5535, 5545, 5625, 5635, 5655,
      5665, 5715, 5725, 581517, 581527, 581547, 581557, 581567, 581577, 5825,
      5835, 5845, 6015, 6115, 6215, 6315, 6415,
    ],
    count: 5,
    startDate: dayjs.tz('2021-08', 'Asia/seoul').startOf('month').toDate(),
    endDate: dayjs.tz('2022-04', 'Asia/seoul').endOf('month').toDate(),
    excludedCompanies: [{ id: 515211, ticker: '323410', name: '카카오뱅크' }],
    image: TailorCaseCardImage1,
    tradeCost: 0.4,
    tags: ['🧘‍', '💰'],
    isDisplay: false,
  },
];

function FTSEBanner() {
  const { logEvent } = useAmplitude();
  const history = useHistory();

  const children = (
    <>
      <Stack
        px="10px"
        direction="row"
        borderRadius="4px"
        bgcolor="#F0E2E8"
        height="25px"
        alignItems="center"
        justifyContent="center"
        width="fit-content"
      >
        <IconVanguard />
        <Typography variant="body2" color="#631C3C" fontWeight="bold">
          공식 인덱스
        </Typography>
      </Stack>
      <Typography fontWeight="bold" variant="h2" color="#FFFFFF">
        FTSE Russell 한국대표지수,
      </Typography>
      <Typography fontWeight="bold" variant="h2" color="#FFF4CF">
        아시아 최초 계약 !
      </Typography>
      <Box flex={1} />
      <>
        <Box display={['initial', 'none']} width="100%">
          <StyledButton
            sx={{
              width: '100%',
              height: '42px',
              fontSize: '14px',
              fontWeight: 'bold',
              flex: 1,
              background: '#332D41',
              color: 'white',
              p: '10px 13px',
              ':hover': { background: '#aaaaaa' },
            }}
            variant="contained"
          >
            바로 확인하기
          </StyledButton>
        </Box>
        <Box display={['none', 'initial']}>
          <Button
            sx={{
              height: '42px',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'white',
            }}
            variant="text"
          >
            바로 확인하기
            <ChevronRightIcon />
          </Button>
        </Box>
      </>
    </>
  );

  return (
    <Box
      sx={{
        width: '100%',
        ':hover': { boxShadow: 3, cursor: 'pointer' },
      }}
      onClick={() => {
        logEvent('ftse banner clicked');
        history.push(`/create-strategy`, {
          preset: {
            id: 100,
            universe: {
              universe: 'index',
              indexCode: 6522,
              name: 'FTSE W KOREA DLY',
              code: 6522,
              region: 'KR',
              currency: 'KRW',
              nationCode: 840,
            },

            tags: [],
          },
        });
      }}
    >
      <Stack
        sx={{
          // backgroundSize: 'contained',
          background: '#7C2E51',
          backgroundRepeat: 'no-repeat',
          // WebkitBackgroundSize: 'contained',
          backgroundImage: `url(${FtsePattern})`,
        }}
        display={['none', 'flex']}
        spacing="8px"
        padding="0px 48px"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="96px"
        bgcolor="#7C2E51"
        borderRadius="8px"
        direction="row"
      >
        {children}
      </Stack>
      <Stack
        sx={{
          backgroundSize: 'cover',
          background: 'rgba(0, 0, 0, 0.04);',
          WebkitBackgroundSize: 'cover',
          backgroundImage: `url(${FtseBackground})`,
        }}
        display={['flex', 'none']}
        spacing="8px"
        padding="24px"
        alignItems="start"
        justifyContent="center"
        width="100%"
        height="231px"
        bgcolor="#7C2E51"
        borderRadius="8px"
        direction="column"
      >
        {children}
      </Stack>
    </Box>
  );
}
export default function StrategySelectionView() {
  const history = useHistory();
  const { logEvent } = useAmplitude();

  return (
    <Box width="100%" flex={1} overflow="auto">
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        minWidth={['initial', '1380px']}
      >
        <Stack
          height="fit-content"
          width="100%"
          py={['50px', '80px']}
          px={['20px', 'initial']}
          alignItems="center"
        >
          <Stack width={['initial', '1380px']} spacing="32px">
            <Typography variant="h1" fontWeight="bold" fontSize="24px">
              내 포트폴리오로 운용하고 싶은 전략을 선택하세요.
            </Typography>

            <Box display={['inherit', 'none']} width="100%">
              <FTSEBanner />
            </Box>
            <FactorPresetList
              presets={lv1Presets.filter((p) => p?.isDisplay !== false)}
              sx={{
                height: '100%',
              }}
            />
            <Box display={['none', 'inherit']} width="100%">
              <FTSEBanner />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
