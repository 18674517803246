import React, {
  createContext,
  Dispatch,
  ReactElement,
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import qs from 'qs';
import amplitude from 'amplitude-js';
import {
  ToastContent,
  ToastOptions,
  toast,
  ToastContainer,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as ls from '~/utils/localStorage';
import { Language } from '~/i18n';
import api, { client } from '~/api';
import { Permission, Profile } from '~/api/user';
import { lightOption } from '~/theme';
import { isNotSupportedBrowser } from './utils/useragent';
import { Usage } from '~/api/screener';

export const MAX_QUERY_FACTOR_COUNT = 30;

const modes = ['normal', 'expert'];
type Mode = typeof modes[number];

type AppState = {
  accessToken?: string | null;
  language: Language;
  // theme: ThemeType;
  user?: Profile | null;
  permission?: Permission | null;
  screenerUsage?: Usage | null;
  mode: Mode;
  showToast: (
    type: 'success' | 'info' | 'error',
    content: ToastContent,
    options?: ToastOptions | undefined,
  ) => React.ReactText;
};

type AppAction =
  | { type: 'SET_LANGUAGE'; language: Language }
  // | { type: 'SET_THEME'; theme: ThemeType }
  | { type: 'SET_ACCESS_TOKEN'; accessToken: string | null }
  | {
      type: 'SET_USER';
      user: Profile | null;
      permission: Permission | null;
      screenerUsage?: Usage | null;
    }
  | { type: 'SET_USAGE'; screenerUsage?: Usage | null }
  | { type: 'SIGN_OUT' }
  | { type: 'SET_MODE'; mode: Mode };

function appReducer(preState: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'SET_LANGUAGE':
      ls.set('language', action.language);
      return { ...preState, language: action.language };
    // case 'SET_THEME':
    //   ls.set('theme', action.theme);
    //   return { ...preState, theme: action.theme };
    case 'SET_ACCESS_TOKEN':
      if (action.accessToken) {
        ls.set('accessToken', action.accessToken);
        return { ...preState, accessToken: action.accessToken };
      }
      ls.remove('accessToken');
      delete preState.accessToken;
      return preState;
    case 'SET_USER':
      if (action.screenerUsage?.planeName) {
        amplitude
          .getInstance()
          .setUserProperties({ plan: action.screenerUsage.planeName });
      }

      return {
        ...preState,
        user: action.user,
        permission: action.permission,
        screenerUsage: action.screenerUsage ?? null,
      };
    case 'SET_USAGE':
      return {
        ...preState,
        screenerUsage: action.screenerUsage,
      };
    case 'SIGN_OUT': {
      ls.remove('accessToken');
      delete preState.accessToken;
      delete preState.user;
      return { ...preState };
    }
    case 'SET_MODE': {
      return { ...preState, mode: action.mode };
    }
    default:
      return { ...preState };
  }
}

export const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<AppAction>;
} | null>(null);

export function AppContextProvider({ children }: { children: ReactElement }) {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const requestInterceptorId = useRef<number>();
  const [state, dispatch] = useReducer<Reducer<AppState, AppAction>>(
    appReducer,
    {
      accessToken: ls.get('accessToken'),
      language: ls.get('language') ?? 'kr',
      // theme: ls.get('theme') ?? 'blue',
      mode: ls.get('mode') ?? 'normal',
      showToast: (type, content, options) => {
        switch (type) {
          case 'success':
            return toast.success(content, options);
          case 'error':
            return toast.error(content, options);
          case 'info':
          default:
            return toast.info(content, options);
        }
      },
    },
  );

  useEffect(() => {
    const query = new URLSearchParams(search);

    let accessToken = state?.accessToken;
    if (query.has('accessToken') || query.has('mode')) {
      if (query.has('mode')) {
        const v = modes.find((v) => v === query.get('mode'));
        dispatch({
          type: 'SET_MODE',
          mode: v ?? 'normal',
        });
        query.delete('mode');
      }
      if (query.has('accessToken')) {
        accessToken = query.get('accessToken') ?? undefined;
        query.delete('accessToken');
      }
      history.replace({
        search: query.toString(),
      });
    }

    if (requestInterceptorId.current) {
      client.interceptors.request.eject(requestInterceptorId.current);
    }

    const signInUrl = `${
      process.env.REACT_APP_API_URL
    }/auth/google?${qs.stringify({
      path: pathname,
      query: query.toString(),
      referrer:
        (document.referrer?.length ?? 0) > 0 ? document.referrer : 'unknown',
    })}`;

    if (accessToken) {
      requestInterceptorId.current = client.interceptors.request.use(
        (config) => {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
          return config;
        },
      );

      api.user
        .profile()
        .then(async (profileRes) => {
          const [permissionRes, usageRes] = await Promise.all([
            api.user.permission(),
            api.screener.usage(),
          ]);
          amplitude.getInstance().setUserId(profileRes.data.id.toString());
          dispatch({
            type: 'SET_ACCESS_TOKEN',
            accessToken: accessToken ?? null,
          });
          dispatch({
            type: 'SET_USER',
            user: profileRes.data,
            permission: permissionRes.data,
            screenerUsage: usageRes.data,
          });
        })
        .catch((error) => {
          if (requestInterceptorId.current) {
            client.interceptors.request.eject(requestInterceptorId.current);
          }
          dispatch({
            type: 'SET_ACCESS_TOKEN',
            accessToken: null,
          });
          dispatch({
            type: 'SET_USER',
            user: null,
            permission: null,
            screenerUsage: null,
          });
          if (error?.response?.status === 401 && !isNotSupportedBrowser()) {
            window.open(signInUrl.toString(), '_self');
          }
        });
    } else if (!isNotSupportedBrowser()) {
      window.open(signInUrl.toString(), '_self');
    }
  }, []);

  const provideValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AppContext.Provider value={provideValue}>
      <ThemeProvider theme={createTheme(lightOption)}>{children}</ThemeProvider>
      <ToastContainer closeOnClick={false} />
    </AppContext.Provider>
  );
}
